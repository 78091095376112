import {Popup} from 'react-leaflet';
import { popupContent } from "./popupStyles";
import { NAVIGATIONAL_STATUS } from '@utils/navigationalStatus';

const PopupCard = ({data}) => {
	const {shipname, type_name, speed, course, status} = data || {};

	function validate(info) {
		return info ? info : 'Desconocido -.';
	}

	function statusCodeToString(code) {
		return `${NAVIGATIONAL_STATUS[code]}`;
	}

	return (
		<Popup>
			<div style={popupContent}>
				<h2>{validate(shipname)}</h2>
				<h4 style={{fontStyle: 'italic'}}>{validate(type_name)}</h4>
				<div className='details'>
					<p>Velocidad: {validate(speed)}</p>
					<p>Rumbo: {validate(course)}</p>
					<p>Estado: {validate(statusCodeToString(status))}</p>
				</div>
			</div>
		</Popup>
	)
}

export default PopupCard;