export const plotlyConfig = {
	layout: {
		autosize: true,
		dragmode: "false",
		paper_bgcolor: "#001c34", plot_bgcolor: "#001c34",
		width: 540, height: 240,
		margin: { r: 25, t: 35, b: 45, l: 25 },
		showlegend: false,
		font: {
			family: 'sans-serif',
			size: 12,
			color: '#fff'
		},
		xaxis: { color: "#FFF", type: "category", rangeslider: { visible: false }, autorange: true},
		yaxis: { color: "#FFF", type: "linear", autorange: true },
	},
	
	config: {
		displaylogo: false, scrollZoom: false, staticPlot: false, displayModeBar: false, 
		responsive: true,
	}
};

export class Line {
	constructor(x=[], y=[]) {
		this.x = x;
		this.y = y;
		this.type = "scatter";
		this.mode = "lines";
		this.line = { color: "rgba(222,192,22,1)" };
	}
}