import { useState, useContext, useEffect } from 'react';
import AlarmCards from './AlarmCards';
import { MapContext } from '@context/MapProvider';
import {ISSUE_TYPES} from './data';
import styles from './styles.module.scss';

const AlarmsSection = () => {
	const {issues} = useContext(MapContext).contextData;
	const [filter, setFilter] = useState('');
	const [filteredIssues, setFilteredIssues] = useState(issues);

	useEffect(()=>{
		let filtered = issues.filter(x => x.name.includes(filter));
		setFilteredIssues(filtered);
	}, [filter, issues]);

	function onFilterChange(e) {
		setFilter(e.target.value);
	}

	return (
		<div className={styles.vertical}>
			<select className={styles.alertsFilter} onChange={onFilterChange}>
					<option key={-1} value=''>Ver todas</option>
					{
						ISSUE_TYPES.map(x => <option key={x.alarmCode} value={x.keyword}>{x.shortName}</option>)
					}
			</select>
			<AlarmCards data={filteredIssues}/>
		</div>
	)
}

export default AlarmsSection;