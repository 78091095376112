import {Marker, Popup} from "react-leaflet";
import { normalizeLatLng } from '@utils/normalizeLatLng';
import styles from './styles.module.scss';

const Buoy = ({data})=> {
	const {volts, ampers, lat, lng, name} = data;

	return(
		<Marker position={[lat,lng]}>
			<Popup>
				<div className={styles.Buoy}>
					<h3>{name}</h3>
					<p>Voltaje: {volts}</p>
					<p>Corriente: {ampers}</p>
					<p>Posición: {normalizeLatLng([lat, lng])}</p>
				</div>
			</Popup>
		</Marker>
	)
}

export default Buoy;