import {useState, useEffect, useContext} from 'react';
import styles from './styles.module.scss';
import axios from 'axios';
import { MapContext } from './../../../context/MapProvider';

const SpeedvarSettings =()=> {
	const alarmType = 'speedvar';
	const alarmDescription = 'Variación de velocidad por tipo de nave (nudos)';

	const {setContextData} = useContext(MapContext);
	const [configuration, setConfiguration] = useState({
		active: false, options: []	
	});

	useEffect(()=>{
		setContextData(prev=> (
			{...prev, config: {...prev.config, alarms:{...prev.config.alarms, [alarmType]: configuration}}})
		);
	},[configuration]);

	const VESSEL_TYPES = [
		'Naves desconocidas',
		'Naves Pesqueras',
		'Remolcadores y Embarcaciones Especiales',
		'Embarcaciones de Recreación',
		'Embarcaciones de Alta Velocidad',
		'Naves de Pasajeros',
		'Naves de Carga',
		'Nave Tanquera',
	];

	const STORAGE_API = '/data/storage';
	useEffect(()=> {
		axios.get(STORAGE_API)
			.then(r=> {
				const loadedConfig = r.data.config.alarms[alarmType];
				let active = loadedConfig.active;
				let options = loadedConfig.options && Object.values(loadedConfig.options).map(v=>v);
				let configObj = {active, options};
				setConfiguration(configObj);
			})
			.catch(e=> console.log(e.message));
	}, []);

	return(
		<details className={styles.BooleanWithInputs}>
			<summary className={styles.optionName}>{alarmDescription}</summary>
			<div className={styles.options}>
				<label className={styles.activationCheckbox}>
					<input type="checkbox" 
						onClick={()=>setConfiguration({...configuration, active: !configuration.active})} 
						checked={configuration.active} />
					<span className={styles.checkboxText}>{configuration.active ? 'Activada' : 'Desactivada'}</span>
				</label>

				<div className={styles.inputSet}>
				{
					VESSEL_TYPES.map((x,i)=> (
						<label key={x}>
							<span>{x}</span>
							<input type="number" name={x} id={i} 
								value={configuration.options[i]} 
								onChange={(e)=>{
									const newOptions = [...configuration.options];
									newOptions[i] = parseInt(e.target.value);
									setConfiguration({...configuration, options: [...newOptions]});
								}} />
						</label>))
				}
				</div>
			</div>
		</details>
	)
}

export default SpeedvarSettings;