import { MapContext } from './../../context/MapProvider';
import { useContext } from 'react';
import { CSVLink } from "react-csv";
import {HEADERS} from './tableHeaders';
import styles from './styles.module.scss';
import { toShortName } from '@utils/issueTypes';

const Searchbar = () => {
	const {contextData} = useContext(MapContext);
	const {vessels, issues} = contextData;

  function getMMSI(vesselId) {
    const vessel =  vessels.find(ship => ship.vesselId == vesselId);
    return vessel?.mmsi;
  }

  function translateIssueNames(issues) {
    if (!issues.length) return;
    const translatedIssues = issues?.map(issue => ({...issue, name: toShortName(issue?.name), vesselMMSI: getMMSI(issue?.vesselId)}));
    return translatedIssues;
  }

	return (
		<div className={styles.searchbar}>
			<label>
			<CSVLink data={translateIssueNames(issues)} headers={HEADERS}>		
				<button 
					style={{padding: '0.5rem', backgroundColor: 'var(--blue-4)', cursor: 'pointer', color: 'white', border: '1px solid var(--blue-1)', outline: 'none', borderRadius: '5px'}}>
						Exportar .csv
				</button>
			</CSVLink>
			</label>
		</div>
	)
}

export default Searchbar;