import styles from './styles.module.scss';
import { SHIPS_PNGS } from './../Ships/ship-icons';
import useMapContext from './../../hooks/useMapContext';

const ShipsLegend = ()=> {
	const [contextData, setContextData] = useMapContext();
	const {shipsFilter} = contextData;

	const LEGENDS = {
		0: 'Desconocido',
		1: 'Naves Pesqueras',
		2: 'Remolcadores y Embarcaciones Especiales',
		3: 'Embarcaciones de Recreación',
		4: 'Embarcaciones de Alta Velocidad',
		5: 'Naves de Pasajeros',
		6: 'Naves de Carga',
		7: 'Nave Tanquera',
	}

	function onSetFilterClick(e){
		const id = e.target.id;
		let newFilter = [];
		if (shipsFilter.includes(Number(id))) {
			newFilter = shipsFilter.filter(x=> x != id)
		} else {
			 newFilter = [...shipsFilter, Number(id)];
		}
		setContextData(prev=>({...prev, shipsFilter: newFilter}));
	}

	return (
		<div className={styles.ShipsLegend}>
			{Object.values(LEGENDS).map((x, i)=>(
				<div className={styles.row} id={Number(i)} onClick={onSetFilterClick}>
					<input type="checkbox" id={Number(i)} onClick={onSetFilterClick} checked={shipsFilter?.includes(Number(i))} 
						/>
					<div className={styles.png}><img src={SHIPS_PNGS[i]} /></div>
					<div className={styles.legend} id={Number(i)} onClick={onSetFilterClick}>{x}</div>
				</div>
			))}
		</div>
	)
}

export default ShipsLegend;