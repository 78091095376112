import { normalizeLatLng } from '@utils/normalizeLatLng';
import { formatTimeString } from '@utils/formatTimeString';
import { toShortName, getIcon } from '../../AlarmsSection/data';
import SpeedRange from './SpeedRange';
import CourseVariation from './CourseVariation';
import SpeedVariation from './SpeedVariation';
import AisNot from './AisNot';
import StatusChange from './StatusChange';
import useMapContext from './../../../../hooks/useMapContext';
import styles from './styles.module.scss';

const AlarmInfo = () => {
	const [contextData] = useMapContext();
	const {selectedIssue} = contextData;
	const {name, timestamp, lat, lon, data} = selectedIssue;

	const ALARMS = {
		speedran: <SpeedRange {...data} />,
		coursevar: <CourseVariation {...data} />,
		speedvar: <SpeedVariation {...data} />,
		aisnot: <AisNot {...data} />,
		statchan: <StatusChange {...data} />,
	}

	return (
		<div className={styles.alarmInfo}>
			<div className={styles.icon}>{getIcon(name)}</div>
			<div className={styles.title}>{toShortName(name)}</div>	
			<div className={styles.info}>
				<h4>{formatTimeString(timestamp)}</h4>
				{normalizeLatLng([lat,lon])}
				<div className={styles.specificInfo}>
					{ALARMS[name]}
				</div>
			</div>
		</div>
	)
}

export default AlarmInfo;