import styles from './styles.module.scss';

const SpeedRange = ({speed, speedRangeThreshold}) => {
	const {min, max} = speedRangeThreshold;
	return (
		<div className={styles.speedRange}>
			<p>Velocidad: {Math.round(speed)} {Math.round(speed) > 1 ? 'nudos' : 'nudo'}</p>
			<p>Mínimo: {min} {Math.round(min) > 1 ? 'nudos' : 'nudo'}</p>
			<p>Máximo: {max} {Math.round(max) > 1 ? 'nudos' : 'nudo'}</p>
		</div>
	)
}

export default SpeedRange;