import { useState, useEffect, useContext } from 'react';
import { MapContainer, Polygon, Polyline, useMapEvent, ZoomControl } from 'react-leaflet';
import 'leaflet-rotatedmarker';
import { MapContext } from '@context/MapProvider';
import Layers from './Layers';
import Ships from '../Ships';
import Buoy from './Buoy';

const Map = () => {
  const [map, setMap] = useState(null);
  const { contextData, setContextData } = useContext(MapContext);
  const { Buoys, drawing, lines, activeZone, linesColor, zoneMarkers, zones, visibleZones, routeTrajectories, routesOn } = contextData;
  const [shipData, setShipData] = useState();

  useEffect(() => {
    const localStorageObject = localStorage.getItem('visibleZones');
    if (localStorage.getItem('visibleZones')) setVisibleZones(JSON.parse(localStorageObject));
  }, []);

  function setVisibleZones(visibleZones) {
    setContextData(prev => ({ ...prev, visibleZones }));
  }

  useEffect(() => {
    if (!map) return;
    setContextData(prev => ({ ...prev, mapRef: map }));
  }, [map]);

  useEffect(() => {
    setShipData(contextData.vessels);
  }, [contextData.vessels]);

  function MapEvents() {
    useMapEvent('click', (e) => { setContextData(prev => ({ ...prev, clickLocation: e.latlng })); });
    useMapEvent('mousemove', (e) => { setContextData(prev => ({ ...prev, cursorLocation: e.latlng })); });
    useMapEvent('contextmenu', () => {
      drawing && setContextData(prev => {
        zoneMarkers[zoneMarkers.length - 1]?.remove();
        return ({ ...prev, zoneMarkers: prev.zoneMarkers.slice(0, prev.zoneMarkers.length - 1) });
      });
    });
    return null;
  }

  return (
    <MapContainer
      center={[-32.32, -71.8]}
      zoom={7}
      zoomControl={false}
      style={{ height: '100%', width: '100%' }}
      whenCreated={setMap}>
      <Layers />
      <ZoomControl position={'bottomright'} />
      <MapEvents />

      <Ships data={shipData} />

      {
        (drawing) &&
        <Polygon positions={lines} pathOptions={{ color: linesColor }} />
      }

      {
        (activeZone?.geometry?.coordinates?.length) &&
        <Polygon
          positions={activeZone.geometry.coordinates}
          pathOptions={{ color: activeZone.color }}
        />
      }

      {
        (zones.length) && zones.map(zone => {
          if (visibleZones[zone?.zoneId]) return (
            <Polygon
              positions={zone.geometry.coordinates}
              pathOptions={{ color: zone.color }}
            />
          );
        }
        )
      }

      {
        routesOn && routeTrajectories?.length && routeTrajectories?.map(x => (
          (<Polyline positions={x?.positions}
            pathOptions={{ color: 'yellow', weight: 5, opacity: 0.5, linecap: 'butt', dashArray: '6 10' }} />)
        ))
      }

      {
        Buoys?.length > 0 &&
        <>
          <Buoy data={Buoys[0]} />
          <Buoy data={Buoys[1]} />
        </>
      }

    </MapContainer>
  );
};

export default Map;