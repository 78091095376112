import ProximitySettings from './ProximitySettings';
import SpeedvarSettings from './SpeedvarSettings';
import StoppedSettings from './StoppedSettings';
import CoursevarSettings from './CoursevarSettings';
import SpeedranSettings from './SpeedranSettings';
import RestrictedSettings from './RestrictedSettings';
import AisnotSettings from './AisnotSettings';
import AisyesSettings from './AisyesSettings';
import RiskSettings from './RiskSettings';
import StatchanSettings from './StatchanSettings';
import StatemerSettings from './StatemerSettings';
import styles from './styles.module.scss';

const SettingsGroup =()=> {
	return (
		<div className={styles.SettingsGroup}>
			<fieldset>
				<legend>Parámetros de alertas</legend>
				<SpeedranSettings />
				<ProximitySettings />
				<SpeedvarSettings />
				<CoursevarSettings />
				<AisnotSettings />
				<AisyesSettings />
				<RestrictedSettings />
				<RiskSettings />
				<StatchanSettings />
				<StatemerSettings />
				<StoppedSettings />
			</fieldset>
		</div>
	)
}

export default SettingsGroup;