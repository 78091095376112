import {useState, useContext} from 'react'
import { MapContext } from '@context/MapProvider';
import styles from './styles.module.scss';

const GoTo = () => {
	const {mapRef} = useContext(MapContext).contextData;
	const [values, setValues] = useState({
		lat: -33.014505,
		lng: -71.641262,
		zoom: 10
	})

	function onGotoClick() {
		mapRef.flyTo([values.lat, values.lng], values.zoom, {animate: true, duration: 0.5});
	}

	return (
		<div className={styles.goTo}>
			<button onClick={onGotoClick}>Ir a... </button>

			<label>
				<span className={styles.text}>Lat:</span> 
				<input
					className={styles.input} 
					type="number" 
					value={values.lat} onChange={e=> setValues(p=>({...p, lat: e.target.value}))}
				/>
			</label>

			<label>
				<span className={styles.text}>Lng:</span> 
				<input 
					className={styles.input} 
					type="number" 
					value={values.lng} onChange={e=> setValues(p=>({...p, lng: e.target.value}))}
				/>
			</label>

			<label>
				<span className={styles.text}>Zoom:</span> 
				<input 
					className={styles.input} 
					type="number" style={{width: '2.5rem'}}
					value={values.zoom} onChange={e=> setValues(p=>({...p, zoom: e.target.value}))}
				/>
			</label>
		</div>
	)
}

export default GoTo;