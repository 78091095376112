import styles from './styles.module.scss';
import {useState, useRef, useEffect, useContext} from 'react';
import { MapContext } from '@context/MapProvider';

const ShipIdsPanel = () => {
	const [list, setList] = useState([]);
	const idInput = useRef('');
	const intemRef = useRef();
	const {contextData, setContextData} = useContext(MapContext);
	const {selectedVesselInfo} = contextData;

	useEffect(()=> {
		idInput.current.value = selectedVesselInfo.mmsi;
	}, [selectedVesselInfo])

	function onAddIdClick() {
		const mmsi = idInput.current.value;
		const isRepeated = () => {
			return list.find(x => x.mmsi === mmsi);
		}
		
		if (mmsi && !isRepeated()) 
			setList(prev => [...prev, selectedVesselInfo]);
		intemRef.current?.scrollIntoView();
	}
	
	useEffect(()=>{
		idInput.current.value = '';
		setContextData(prev => ({...prev, vesselsInZone: list}))
	},[list]);

	function onRemoveClick(id) {
		const newList = list.filter(x => x.mmsi !== id);
		setList(newList);
	}

	const itemsList = list.map(
		vessel => (<div className={styles.listItem} onClick={()=> onRemoveClick(vessel.mmsi)}>
			<p className={styles.id}  ref={intemRef}>{vessel?.mmsi}</p>
			<p className={styles.name}>{vessel?.shipname ? vessel?.shipname : 'Desconocido-.'}</p>
		</div>));

	return (
		<fieldset className={styles.shipIdsPanel}>
			<legend>Cliquee en la nave para agregar</legend>
			<input type="text" ref={idInput} readonly='true'/>
			<button onClick={onAddIdClick}>+</button>
			<div className={styles.list} >
				{itemsList}
			</div>
		</fieldset>
	)	
}

export default ShipIdsPanel;