import {useState, useContext, useEffect} from 'react';
import {MapContext} from '@context/MapProvider';
import { flyTo } from '@components/Map/utils';
import { getIcon} from '../data';
import { AiTwotoneAlert } from "react-icons/ai";
import styles from './styles.module.scss';
import withTagContext from './withTagContext';

const AlarmCard = ({name, data, tagData:tag}) => {
	const {contextData, setContextData} = useContext(MapContext);
	const {mapRef, vessels, popupRefs, selectedIssue} = contextData;
	const [notification, setNotification] = useState();

	useEffect(()=> {
		const notificationValue = localStorage.getItem(data.issueId);
		setNotification(!notificationValue);
	}, []);

	function onCardClick() {
		const alarmVesselId = data.vesselId;
		const vesselInfo = vessels.find(x => x?.vesselId === alarmVesselId);
		setContextData(prev=>({...prev, selectedIssue: data, selectedVesselInfo: vesselInfo}));
		
		if (vesselInfo?.lat && vesselInfo?.lon) flyTo(mapRef, popupRefs[alarmVesselId], [vesselInfo?.lat, vesselInfo?.lon], 15);

		setNotification(false);
		localStorage.setItem(data.issueId, false);
		
		tag.getTagValue();
	}

	return (
		<div className={`
				${styles.alarmCard} 
				${selectedIssue?.issueId===data.issueId && styles.active}
			
				${notification && styles.hasNotification}
				${tag.value && styles[tag.value]}
			`} 
			onClick={onCardClick}>
			<div className={styles.icon}>{getIcon(data.name)}</div>
			<div className={styles.name}>{name}</div>

			{notification && <div className={styles.notification}><AiTwotoneAlert /></div>}
			<div className={styles.tag}>{tag.interfaceElement}</div>
		</div>
	)
}

export default withTagContext(AlarmCard);