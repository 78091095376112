import L from 'leaflet';
import { 
	GiShipWreck, GiShipBow, GiShipWheel, GiSinkingShip, GiScoutShip, GiIronHulledWarship, GiInterceptorShip, 
	GiCargoShip, GiBattleship 
} from "react-icons/gi";

import GreenShip from '../../icons/ships/green-ship.png';
import WhiteShip from '../../icons/ships/white-ship.png';
import BlueShip from '../../icons/ships/blue-ship.png';
import CyanShip from '../../icons/ships/cyan-ship.png';
import OrangeShip from '../../icons/ships/orange-ship.png';
import PinkShip from '../../icons/ships/pink-ship.png';
import RedShip from '../../icons/ships/red-ship.png';
import VioletShip from '../../icons/ships/violet-ship.png';
import YellowShip from '../../icons/ships/yellow-ship.png';

export const SHIPS_PNGS = [RedShip, WhiteShip, BlueShip, PinkShip, GreenShip, YellowShip, CyanShip, VioletShip];

export const SHIP_ICONS = [<GiShipWreck />, <GiShipBow />, <GiShipWheel />, <GiSinkingShip />, <GiScoutShip />, 
	<GiIronHulledWarship />, <GiInterceptorShip />, <GiCargoShip />, <GiBattleship />]

export function getShipIcon(color, size=2) {
	const sizes = [[6,11], [12,22], [18,33], [24,44], [30,55]]
	const LeafIcon = L.Icon.extend({options: {iconSize: sizes[size]}});
	const greenShip = new LeafIcon({ iconUrl: GreenShip });
	const blueShip = new LeafIcon({ iconUrl: BlueShip });
	const cyanShip = new LeafIcon({ iconUrl: CyanShip });
	const orangeShip = new LeafIcon({ iconUrl: OrangeShip });
	const pinkShip = new LeafIcon({ iconUrl: PinkShip });
	const redShip = new LeafIcon({ iconUrl: RedShip });
	const violetShip = new LeafIcon({ iconUrl: VioletShip });
	const yellowShip = new LeafIcon({ iconUrl: YellowShip });
	const SHIP_COLORS = [redShip, orangeShip, blueShip, pinkShip, greenShip, yellowShip, cyanShip, violetShip];
	return SHIP_COLORS[color];
}