import { useContext } from 'react';
import {useHistory} from 'react-router-dom';
import Searchbar from './Searchbar';
import { MapContext } from '@context/MapProvider';
import styles from './styles.module.scss';
import { formatTimeString } from '@utils/formatTimeString';
import { normalizeLatLng } from '@utils/normalizeLatLng';
import { getStatusString } from '@utils/navigationalStatus';

const VesselsTable = () => {
	const {contextData, setContextData} = useContext(MapContext);
	const {vessels} = contextData;
	const history = useHistory();

	function onRowClick(vessel){
		setContextData(prev=> ({...prev, selectedVesselInfo: vessel, clickedRow: vessel}));
		history.push('/');
	}

	return (
			<div className={styles.alarms_list}>
				<h2>Lista de naves en sistema</h2>
				<Searchbar/>
				<table>
					<thead>
						<tr>
							<td>MMSI</td>
							<td>IMO</td>
							<td>Ubicación</td>
							<td>Fecha y Hora</td>
							<td>Proa</td>
							<td>Velocidad promedio</td>
							<td>Rumbo promedio</td>
							<td>Status</td>
							<td>Descripción</td>
							<td>Nombre</td>
							<td>Callsign</td>
							<td>Bandera</td>
							<td>Eslora</td>
							<td>Manga</td>
							<td>Arqueo bruto</td>
							<td>Calado</td>
						</tr>
					</thead>
					<tbody>
						{
							vessels.map(vessel => 
								<tr key={`${vessel.lat}${vessel.lon}${vessel.mmsi}`}
									onClick={()=> onRowClick(vessel)}>
										<td>{vessel.mmsi}</td>
										<td>{vessel.imo}</td>
										<td>{normalizeLatLng([vessel.lat, vessel.lon])}</td>
										<td>{formatTimeString(vessel.timestamp)}</td>
										<td>{Math.round(vessel.heading)}°</td>
										<td>{Math.round(vessel.avg_speed)}</td>
										<td>{Math.round(vessel.avg_course)}°</td>
										<td>{getStatusString(vessel.status)}</td>
										<td>{vessel?.type_name}</td>
										<td>{vessel?.shipname}</td>
										<td>{vessel?.callsign}</td>
										<td>{vessel?.flag}</td>
										<td>{vessel?.length}m</td>
										<td>{vessel?.width}m</td>
										<td>{vessel?.grt}</td>
										<td>{vessel?.draught}</td>
								</tr>
							)
						}
					</tbody>
				</table>
			</div>
		)
}

export default VesselsTable;