export const VESSEL_TYPES = {
	'Naves Pesqueras': false,
	'Remolc. y Embarc. Especiales': false,
	'Embarcaciones de Recreación': false,
	'Embarcaciones de Alta Velocidad': false,
	'Naves de Pasajeros': false,
	'Naves de Carga': false,
	'Naves Tanqueras': false,
  'Desconocido': false,
}

export const ALERT_TYPES = {
	'Nave deja de transmitir su posición por AIS': false,
	'Nave comienza a transmitir su posición por AIS': false,
	'Nave de lista negra en Zona Económica Exclusiva': true,
	'Nave dentro de zona ilegal': false,
	'Nave dentro de zona de peligro': false,
	'Nave varía su velocidad de manera abrupta': false,
	'Nave varía su velocidad de manera significativa': false,
	'Nave varía su rumbo de manera abrupta': true,
	'Nave varía su rumbo de manera significativa': true,
	'Dos naves muy cerca': true,
	'Velocidad fuera de su rango normal': false,
	'Nave cambia de estado de navegación AIS': false,
	'En faena de pesca: false alta intensidad de viento': false,
	'Estado de navegación AIS de emergencia': true
};

export const RANKER_COEFFICIENTS = {
	'Alarmas en un mismo buque': 10,
	'Tiempo de duración de la alarma': 73,
	'Buques con la misma alamarma en la cercanía': 46 ,
	'Buques dejan de transmitir señal AIS': 50,
	'Buques comienzan a trasmitir señal AIS': 21,
	'Buque en lista negra dentro de EEZ': 5,
	'Buques dentro de zona ilegal': 90,
	'Buques dentro de zona de peligro': 100,
	'Variación de velocidad inesperada': 50,
	'Buque se detuvo': 40,
	'Variación inesperada de rumbo': 60,
	'Variación de rumbo significativa': 65,
};

export const VESSELS_DISPLAY = {
	'Duración de advertencia (minutos)': 120,
	'Dejar de mostrar (minutos)':  720
}