import styles from './styles.module.scss';
import { getStatusString } from '@utils/navigationalStatus';

const StatusChange = ({lastStatus, actualStatus}) => {
	return (
		<div className={styles.StatusChange}>
			<p>Anterior: {getStatusString(lastStatus)}</p>
			<p>Actual: {getStatusString(actualStatus)}</p>
		</div>
	)
}

export default StatusChange;