import styles from './styles.module.scss';
import { getIcon, toShortName } from './../../../AlarmsSection/data';
import {useState, useEffect, useContext} from 'react';
import { MapContext } from '@context/MapProvider';

const AlarmsForVessel = () => {
	const {contextData, setContextData} = useContext(MapContext);
	const {selectedVesselInfo, issues} = contextData;
	const [filteredIssues, setFilteredIssues] = useState([]);

	function getIssuesForVessel() {
		return issues?.filter(x => x?.vesselId === selectedVesselInfo?.vesselId);
	}

	useEffect(()=>{
		setFilteredIssues(getIssuesForVessel());
	}, [selectedVesselInfo, issues]);

	
	return (
		<div className={styles.alarmsForVessel}>
			{
				filteredIssues?.map(issue=> <Card data={issue} setContextData={setContextData} key={issue.issueId} />)
			}
		</div>
	)
}

const Card = ({data, setContextData}) => {
	function onCardClick() {
		setContextData(prev=>({...prev, selectedIssue: data}));
	}

	return (
		<div className={styles.alarmCard} onClick={onCardClick}>
			<div className={styles.icon}>{getIcon(data.name)}</div>
			<div className={styles.name}>{toShortName(data.name)}</div>
		</div>
	)
}

export default AlarmsForVessel;