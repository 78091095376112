import {MapContext} from '@context/MapProvider';
import {useContext} from 'react';
import { vesselIdsToDescriptions } from './../utils';
import styles from './styles.module.scss';

export const ZoneInfo = () => {
	const {activeZone} = useContext(MapContext).contextData;
	const descriptions = vesselIdsToDescriptions(activeZone.vesselTypes);
	
	const alerts = activeZone.issueTypes;
	function getActiveAlerts(){
		let activeAlerts = [];
		if (alerts){
			 activeAlerts = Object.entries(alerts)?.filter(([k,v])=> {
				if (v) return k;
			});
		}
		return activeAlerts;
	}
	
	return (
		<div className={styles.zoneInfo}>
			<div className={styles.mode}>MODO: {activeZone.mode}</div>
			
			{activeZone.mode !== 'inactive' && <>
				<div className={styles.issue}>ISSUE: {activeZone.issue}</div>

				<div className={styles.shipTypes}>TIPOS: 
					<ul>
						{
							descriptions?.map(x=><li key={x}>{`${x} `}</li>)
						}
					</ul>
				</div>

				<div className={styles.vessels}>NAVES: 
					<ul>
						{
							activeZone.vesselsInfo?.map(x=><li key={x}>{`${x.mmsi} : "${x.name}" `}</li>)
						}
					</ul>
				</div>
			</>}

			{activeZone.mode === 'inactive' &&
			<div className={styles.shipTypes}>ALERTAS: 
				<ul>
					{
						getActiveAlerts()?.map(x=><li key={x}>{`${x} `}</li>)
					}
				</ul>
			</div>}
		</div>
	)
}