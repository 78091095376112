import styles from './styles.module.scss';

const NamePanel = ({name, setName}) => {
	return (
		<fieldset className={styles.name}>
			<legend>Descripción</legend>
			<input type="text" value={name} maxlength={50}
				onChange={(e)=>setName(e.target.value)}/>
		</fieldset>
	)
}

export default NamePanel;