import styles from './styles.module.scss';
import ZonesList from '@views/MapScreen/Sidebar/ZonesBar2/ZonesList/index';

const ZonesMenu = () => {
	
	return (
		<div className={styles.zonesMenu}>
			<ZonesList />
		</div>
	)
}

export default ZonesMenu;