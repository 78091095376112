import {useContext} from 'react';
import { MapContext } from '@context/MapProvider';
import styles from './styles.module.scss';

const IssueType = () => {
	const {setContextData} = useContext(MapContext);

	return (
		<fieldset className={styles.issueType}>
			<legend>Tipo de issue</legend>
			<select onChange={(e)=> setContextData(prev=>({...prev, zoneIssue: e.target.value}))}>
				<option value="risk">Risk</option>
				<option value="restricted">Restricted</option>
			</select>			
		</fieldset>
	)
}

export default IssueType;