import {RANKER_COEFFICIENTS} from '../../utils/data';
import styles from './styles.module.scss';
import SliderSet from './SliderSet';
import MainButton from './../../UI/MainButton/MainButton';
import HideOptions from './HideOptions/index';
import useConfig from './../../hooks/useConfig';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SettingsGroup from './SettingsGroup';

const SettingsScreen = () => {
	const [, setConfig] = useConfig();

	return (
		<div className={styles.settingsScreen}>
			<div className={styles.options}>
				<ToastContainer />
				<SettingsGroup />
			  <SliderSet legend={'Coeficientes'} options={RANKER_COEFFICIENTS} />
				<HideOptions />
			</div>
			<div className={styles.toolbar}>
				<MainButton 
					onClick={setConfig}
					children={'Aplicar Configuración'}
					type={'save'}
					>
				</MainButton>
			</div>
		</div>
	)
}

export default SettingsScreen;