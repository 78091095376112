import {useContext} from 'react';
import { MapContext } from '@context/MapProvider';
import {ReactComponent as LocationIcon} from './icons/location.svg';
import {ReactComponent as CourseIcon} from './icons/course.svg';
import {ReactComponent as HeadingIcon} from './icons/heading.svg';
import {ReactComponent as SpeedIcon} from './icons/speed.svg';
import {ReactComponent as DestinationIcon} from './icons/destination.svg';
import { getVesselSchema } from '@views/MainScreen/DetailsSection/VesselDetail/data';
import styles from './styles.module.scss';
import { flyTo } from './../../../../../components/Map/utils';

const ShipInfo = () => {
	const {selectedVesselInfo, mapRef} = useContext(MapContext).contextData;
	const {location, heading, avg_speed, course, destination, vessel_type, length, width, dwt, draught, grt, year_built} = selectedVesselInfo || {};
	
	function normalizeLatLng(latlng) {
		if (!latlng) return `- , -`;
		let LAT = latlng && Number(latlng[0])?.toFixed(4);
		let LNG = latlng && Number(latlng[1])?.toFixed(4);
		LAT > 0 ? LAT+='N' : LAT=Math.abs(LAT)+'S';
		LNG > 0 ? LNG+='E' : LNG=Math.abs(LNG)+'W';
		return `${LAT} , ${LNG}`;
	}

	return (
		<div className={styles.shipInfo}>
			<div className={styles.imageContainer}>
				{
					getVesselSchema(vessel_type)
				}
				<div className={styles.properties}>
					<div>Eslora: { length ? <span>{length}m</span> : '---' }</div>
					<div>Manga: { width ? <span>{width}m</span> : '---' }</div>
					<div>Calado: { draught ? <span>{draught}m</span> : '---' }</div>
					<div>Peso (DWT): { dwt ? <span>{dwt}</span> : '---' }</div>
					<div>Arqueo Bruto: { grt ? <span>{grt}</span> : '---' }</div>
					<div>Año Constr.: { year_built ? <span>{year_built}</span> : '---'}</div>
				</div>
			</div>

			<div className={styles.visualInfo}>
				<div className={styles.location}
						onClick={()=> flyTo(mapRef, null, [selectedVesselInfo.lat, selectedVesselInfo.lon], 16)}
					>
					<LocationIcon className={styles.icon}/>	
					<span className={styles.locationText}>{normalizeLatLng(location)}</span>

				</div>
				<div className={styles.heading}>
					<HeadingIcon className={styles.icon}/>	
					<div className={styles.text}>{heading && Math.round(heading)}°</div>
					<div className={styles.legend}>Proa</div>

				</div>
				<div className={styles.course}>
					<CourseIcon className={styles.icon}/>	
					<div className={styles.text}>{course && Math.round(course)}°</div>
					<div className={styles.legend}>Rumbo</div>
				</div>
				<div className={styles.avgSpeed}>
					<SpeedIcon className={styles.icon}/>	
					<div className={styles.text}>{avg_speed?.toFixed(2)}</div>
					<div className={styles.legend}>Velocidad</div>
				</div>
				<div className={styles.destination}>
					<DestinationIcon className={styles.icon}/>	
					<div className={styles.text}>{destination}</div>
					<div className={styles.legend}>Puerto Destino</div>

				</div>
			</div>
		</div>
	)
}

export default ShipInfo;