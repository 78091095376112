const DESCRIPTIONS = {
  'Desconocidos': 0,
	'Naves Pesqueras': 1,
	'Remolc. y Embarc. Especiales': 2,
	'Embarcaciones de Recreación':3 ,
	'Embarcaciones de Alta Velocidad': 4,
	'Naves de Pasajeros': 5,
	'Naves de Carga': 6,
	'Naves Tanqueras':7
};

const VESSEL_TYPES = {
  0: 'Desconocidos',
	1: 'Naves Pesqueras',
	2: 'Remolc. y Embarc. Especiales',
	3: 'Embarcaciones de Recreación',
	4: 'Embarcaciones de Alta Velocidad',
	5: 'Naves de Pasajeros',
	6: 'Naves de Carga',
	7: 'Naves Tanqueras',
};

export const ISSUE_TYPES = {
	'Distancia mínima de acercamiento': true,
	'Pérdida de Señal AIS': true,      
	'Nueva Señal AIS': true,       
	'Variación de velocidad': true,       
	'Variación de rumbo (grados)': true,     
	'Rango de velocidad': true,       
	'Cambio de status AIS': true,       
	'Status de AIS de emergencia ': true,    
};

export function getAlarmKey(name){
	const alarmKeys = {
		'Distancia mínima de acercamiento': 'proximity',
		'Pérdida de Señal AIS': 'aisnot',      
		'Nueva Señal AIS': 'aisyes',       
		'Variación de velocidad': 'speedvar',       
		'Variación de rumbo (grados)': 'coursevar',     
		'Rango de velocidad': 'speedran',       
		'Cambio de status AIS': 'statchan',       
		'Status de AIS de emergencia ': 'statemer',   
	};
	return alarmKeys[name];
}

export function vesselDescriptionsToIds(vessels) {
	const ids = [];
	Object.entries(vessels)
		.forEach(([key, value])=> {
			if (value) ids.push(DESCRIPTIONS[key]);
		});
	return ids;
}

export function vesselIdsToDescriptions(ids) {
  const cleanUp = ids.map(x => {
    if (!x) return 0;
    else return x;
  })
  const descriptions = cleanUp.map(x => VESSEL_TYPES[x]);
	return descriptions;
}