import {useState} from 'react';
import MapProvider from '../context/MapProvider';
import styles from './App.module.css';
import Footbar from '../components/Footbar';
import UserRoutes from '../routes/UserRoutes';
import InfoBar from '../components/InfoBar';
import LoginScreen from './../pages/LoginScreen/index';

function App() {
	const [auth, setAuth] = useState(false);
	return (
		<MapProvider>
			{
				auth ?
					<div className={styles.container}>
					<InfoBar />
					<div className={styles.desktop}>
						<UserRoutes />
					</div>
					<Footbar />
					</div>
				:
					<LoginScreen setAuth={setAuth}/>
			}
		</MapProvider>
	);
}

export default App;