import {useState, useEffect, useContext} from 'react';
import { MapContext } from '@context/MapProvider';

import styles from './styles.module.scss';

const MiniBooleanSet = ({legend, options, contextKey}) => {
	const {setContextData} = useContext(MapContext);
	const [values, setValues] = useState({});

	useEffect(()=>{ 
		setValues(options);
	}, []);

	function onClick(e) {
		setValues(prev=> ({...prev, [e.target.name]: !prev[e.target.name]}));
	}

	useEffect(()=>{
		setContextData(prev=> ({...prev, [contextKey]: values}));
	},[values]);

	return (
		<fieldset className={styles.miniBooleanSet}>
			<legend>{legend}</legend>
			{
				Object.entries(options)?.map(([key,value], i) => (
					<label key={key}>
						<input type="checkbox" id={i+1} name={key} onClick={onClick} checked={values[key]}/>
						{key}
					</label>
				))
			}
		</fieldset>
	)
}

export default MiniBooleanSet;