import AlarmsForVessel from './AlarmsForVessel/index';
import ShipInfo from './ShipInfo/index';
import styles from './styles.module.scss';

const VesselDetail = () => {
	return (
		<div className={styles.vesselDetail}>
			<ShipInfo />
			<AlarmsForVessel />
		</div>
	)
}

export default VesselDetail;