import axios from 'axios';

const OPTIONS = {
	headers: { 'Content-Type': 'application/json' },
	withCredentials: true
};

const ignoreList = [
  'https://seaguardian-bi.herokuapp.com/data/search/vessels'
];

export function setupServices() {
	const devUriBase = "http://localhost:3001";
	const prodUriBase = "https://sea-guardian.herokuapp.com";
  const railwayUriBase = "";
	let getBaseUri = () => window.location.host.includes("localhost") ? devUriBase : prodUriBase;
	axios.interceptors.request.use((req) => {
    if (ignoreList.includes(req.url)) {
      console.log('ignoreeeeeeeeeeeeeddd');
      return req;
    }  
		return { ...req, url: getBaseUri() + req.url };
	}, null, { synchronous: true });
}

// User ---
export const getInfo = async (email) => {
	const response = await axios.post(`/users/getInfo`, { email }, OPTIONS);
	return response.data;
};
