export const NAVIGATIONAL_STATUS = {
		0 : 'under way using engine',
		1 : 'at anchor',
		2 : 'not under command' ,
		3 : 'restricted maneuverability',
		4 : 'constrained by her draught',
		5 : 'moored',
		6 : 'aground' ,
		7 : 'engaged in fishing',
		8 : 'under way sailing', 
		9 : 'reserved for DG, HS, or MP, or IMO hazard or pollutant category C, high-speed craft (HSC)', 
		10 : 'reserved for dangerous goods (DG), harmful substances (HS) or marine pollutants (MP), or IMO hazard or pollutant category A, wing in ground (WIG)', 
		11 : 'power-driven vessel towing astern (regional use)',
		12 : 'power-driven vessel pushing ahead or towing alongside (regional use)',
		13 : 'reserved for future use',
		14 : 'AIS-SART (active), MOB-AIS, EPIRB-AIS',
		15 : 'undefined : default (also used by AIS-SART, MOB-AIS and EPIRB-AIS under test)',
		95 : 'Base Station',
		96 : 'Class B',
		97 : 'SAR Aircraft',
		98 : 'Aid to Navigation',
		99 : 'Class B',
}

export function getStatusString(code) {
	return `~${code}. ${NAVIGATIONAL_STATUS[code]}`;
}
