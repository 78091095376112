import {useContext} from 'react'
import styles from './styles.module.scss';
import { SHIP_ICONS } from './../Ships/ship-icons';
import { MapContext } from './../../context/MapProvider';
import { flyTo } from './../Map/utils';

const MiniShipCard = ({data}) => {
	const {contextData} = useContext(MapContext);
	const {mapRef, vessels, popupRefs} = contextData;

	function onClick() {
		const vesselId = data.vesselId;
		const vesselInfo = vessels.find(x => x.vesselId === vesselId);
		flyTo(mapRef, popupRefs[vesselId], [vesselInfo.lat, vesselInfo.lon], 13);
	}

	let vesselType = 0;
	if (data.vessel_type > 8 || !data.vessel_type) {
		vesselType = 0;
	} else {
		vesselType = data.vessel_type;
	}

	return (
		<div className={styles.card} onClick={onClick}>
			<div className={styles.icon}>
				{SHIP_ICONS[vesselType]}
			</div>
			<div className={styles.text}>
				{data?.shipname}
			</div>
		</div>
	)
}

const ShipsList = () => {
	const {selectedIssue, vesselsForIssue} = useContext(MapContext).contextData;
	let shipsList = [];

	if (selectedIssue?.data?.nearbyVessels?.length) {
		shipsList = selectedIssue?.data.nearbyVessels;
	} else
	{
		shipsList = vesselsForIssue;
	}
  console.log(shipsList)

	return (
		<div className={styles.container}>
			{
				shipsList?.map(v => <MiniShipCard data={v}/>)
			}
		</div>
	)
}
export default ShipsList;