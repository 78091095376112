import { useState, useEffect, createContext } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
export const MapContext = createContext();

const URI_STORAGE = '/data/storage';
const socket = io("https://sea-guardian.herokuapp.com");

const MapProvider = ({ children }) => {
	const [contextData, setContextData] = useState({
		// Configuration
		config: {},

		// Menues
		activeSidebar: true,

		// Map and its references---
		mapRef: null,
		clickLocation: [],
		rightClickLocation: [],
		cursorLocation: {},
		vessels: [], //... ships plotted on the map ---
		popupRefs: {}, //... used to dynamically open popups ---
		zoom: 10,

		// Markers placed using the LocationsBar component ---
		markers: [],

		// Zones defined via the ZonesBar2 component ---
		zones: [],
		systemZones: null,
		userZones: null,

		activeZone: null,

		zoneMode: 'active',
		vesselsInZone: [],
		vesselTypesInZone: [],
		zoneIssue: 'risk',
		visibleZones: {},

		// While a zone is being drawn ---
		drawing: false,
		zoneMarkers: [],
		lines: [], //... lines that are plotted by <Polygon /> in the map screen
		hoveredMarker: 0,
		selectedMarker: 0,
		linesColor: 'white',

		// Selected vessel
		selectedVesselInfo: {},

		// Alarms or alerts on the main screen ---
		issues: [],
		selectedIssue: null,
		tagValue: null,
		tagValues: null,

		// Data for components on the main screen --
		rightPanelContent: [],
		vesselsForIssue: [],

		// Window dimensions
		windowDimensions: [],

		shipIconSize: 1,

		// Graphics
		graphicsOn: false,
		vesselRoute: [],

		// Routes
		routesOn: false,
		routeTrajectories: [],

		Buoys: [],

		clickedRow: null,

		//Filters
		shipsFilter: [0, 1, 2, 3, 4, 5, 6, 7]
	});

	// Bring vessels info from API and store in Context.
	useEffect(() => {
		axios.get(URI_STORAGE)
			.then((res) => {
				let { vessels, issues, zones, config } = res.data;
				if (vessels?.length) setContextData(prev => ({ ...prev, vessels }));
				if (issues?.length) setContextData(prev => ({ ...prev, issues }));
				if (zones?.length) setContextData(prev => ({ ...prev, zones }));
				if (Object.keys(config)?.length) setContextData(prev => ({ ...prev, config }));
			})
			.then(() => {
				socket.on('info', data => {
					let { vessels, issues } = data;
					setContextData((prevContext) => ({ ...prevContext, vessels, issues }));
				});
			})
			.catch(err => console.error(err.message));
	}, []);

	useEffect(() => {
		setContextData(prev => ({ ...prev, windowDimensions: [window.innerWidth, window.innerHeight] }));
	}, []);

	return (
		<MapContext.Provider value={{ contextData, setContextData }}>
			{children}
		</MapContext.Provider>
	);
};

export default MapProvider;