import React from "react";
import { Switch, Route } from "react-router-dom";
import MainScreen from './../pages/MainScreen';
import MapScreen from './../pages/MapScreen';
import AlarmsReport from '../pages/AlarmsScreen';
import SettingsScreen from '../pages/SettingsScreen';
import ReportsScreen from './../pages/ReportsScreen';
import VesselsReport from './../pages/VesselsScreen';

const UserRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={MainScreen} />
      <Route path="/map-screen" component={MapScreen} />
      <Route path="/reports-screen" component={ReportsScreen} />
      <Route path="/vessels-report" component={VesselsReport} />
      <Route path="/alarms-report" component={AlarmsReport} />
      <Route path='/settings' component={SettingsScreen} />
    </Switch>
  );
};

export default UserRoutes;