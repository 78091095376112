import styles from './styles.module.scss';

const NewContextMenu = ({children, onClick}) => {
	return (
		<div className={styles.newContextMenu} onClick={onClick}>
			<div className={styles.menuItems}>
				{children}
			</div>
		</div>
	)
}

export default NewContextMenu;