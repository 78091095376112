import {useState, useEffect} from 'react';
import useConfig from './../../../hooks/useConfig';
import useMapContext from './../../../hooks/useMapContext';
import styles from './styles.module.scss';

const SliderSet = ({legend, options}) => {
	const [config] = useConfig();
	const [, setContextData] = useMapContext();

	const paramsPriorizator = {
		"proximity": 0.5,
		"restricted": 0.5,
		"risk": 0.5,
		"blackList": 0.5,
		"aisnot": 0.5,
		"aisyes": 0.5,
		"speedvar": 0.5,
		"stopped": 0.5,
		"coursevar": 0.5,
		"speedran": 0.5,
		"statchan": 0.5,
		"statemer": 0.5,
		"quantity": 0.5
};
const [values, setValues] = useState(paramsPriorizator);

const paramsNames = {
	'Buques con la misma alamarma en la cercanía': 46,
	'Buques dentro de zona ilegal': 90,
	'Buques dentro de zona de peligro': 100,
	'Buque en lista negra dentro de EEZ': 5,
	'Buques dejan de transmitir señal AIS': 50,
	'Buques comienzan a trasmitir señal AIS': 21,
	'Variación de velocidad inesperada': 50,
	'Buque se detuvo': 40,
	'Variación inesperada de rumbo': 60,
	'Rango de velocidad': 50,
	'Cambio de estado': 50,
	'Estado emergencia': 50,
	'Cantidad de alarmas en la misma nave': 50,
}

	useEffect(()=>{ setValues(config.paramsPriorizator)}, []);

	function findKey(name){
		let index = Object.keys(paramsNames).findIndex(x=> x===name);
		return Object.keys(paramsPriorizator)[index];
	}

	function onChange(e) {
		let newValues = {...values};
		newValues[e.target.name] = e.target.value/100;
		setValues(newValues);
	}

	useEffect(()=>{
		const config = {paramsPriorizator: values};
		setContextData(prev=>({...prev, config: {...prev.config, ...config}}));
	},[values])

	return (
		<div className={styles.SliderSet}>
			<fieldset>
				<legend>{legend}</legend>
				{
					Object.entries(paramsNames).map(([key,value]) => (
						<label key={key}>
							<div className={styles.text}>
								{key}
							</div>
							<div className={styles.slider_container}>
								<input type="range" min='0' max='100' name={findKey(key)} 
									value={Math.round(values?.[findKey(key)]*100)} 
									onChange={onChange} />
								{Math.round(values?.[findKey(key)]*100)}
							</div>
						</label>
					))
				}
			</fieldset>
		</div>)
}

export default SliderSet;