import VesselDetail from './VesselDetail';
import AlarmDetail from '../DetailsSection/AlarmDetail';
import Graphic from './Graphics/index';
import { MapContext } from '@context/MapProvider';
import { useContext, useState, useEffect } from 'react';
import HeadingBar from '../DetailsSection/HeadingBar';
import styles from './styles.module.scss';
import RouteTable from './RouteTable/index';
import axios from 'axios';

const DetailsSection = () => {
	const {contextData, setContextData}= useContext(MapContext);
	const {graphicsOn, routesOn, selectedVesselInfo, vessels} = contextData;
	const [topGraph, setTopGraph] = useState([]);
	const [bottomGraph, setBottomGraph] = useState([]);

	useEffect(()=> {
		const vesselId = selectedVesselInfo?.vesselId;
		vesselId && axios.get(`/data/visual/${vesselId}`)
			.then(res=> {
				let timestamps = res.data.graphics.time;
				setTopGraph({values: res.data.graphics.speed, timestamps});
				setBottomGraph({values: res.data.graphics.course, timestamps});
				setContextData(prev=>({...prev, vesselRoute: res.data.positions.coordinates}));
			})
			.catch(err=> console.error(err.message));
	}, [graphicsOn, selectedVesselInfo, vessels]);

	return (
		<div className={styles.configurableGrid}>
			<div className={styles.topPanel}>
				{
					graphicsOn ? 
						<Graphic title={'Velocidad'} data={topGraph} />
						:
						<AlarmDetail />
				}
			</div>
			<HeadingBar />
			<div className={styles.bottomPanel}>
				{
					graphicsOn ? 
						<Graphic title={'Rumbo'} data={bottomGraph} />
						:
						<VesselDetail />
				}
				{/* {
					routesOn ? 
						// <RouteTable />
						:
						<VesselDetail />
				} */}
			</div>
		</div>
	)
}

export default DetailsSection;