import Chile from '../../icons/flags/svg/Chile.svg';
import Liberia from '../../icons/flags/svg/Liberia.svg';
import HongKong from '../../icons/flags/svg/Hong Kong.svg';
import MarshallIsland from '../../icons/flags/svg/Marshall Island.svg';
import Panama from '../../icons/flags/svg/Panama.svg';
import Singapore from '../../icons/flags/svg/Singapore.svg';
import UnitedKingdom from '../../icons/flags/svg/United Kingdom.svg';

const FLAGS = {
	CL: Chile,
	HK: HongKong,
	LR: Liberia,
	MH: MarshallIsland,
	PA: Panama,
	SG: Singapore,
	GB: UnitedKingdom
}

export function getFlag(id) {
  if (!id) return;
	if (id in FLAGS) return FLAGS[id];
}

/*
const FLAGS = {
	AL	:	Albania,
	AD	:	Andorra,
	AT	:	Austria,
	PT	:	Portugal,
	BE	:	Belgium,
	BY	:	Belarus,
	BG	:	Bulgaria,
	VA	:	Vatican,
	CY	:	Cyprus,
	CY	:	Cyprus,
	DE	:	Germany,
	CY	:	Cyprus,
	GE	:	Georgia,
	MD	:	Moldova,
	MT	:	Malta,
	AM	:	Armenia,
	DE	:	Germany,
	DK	:	Denmark,
	DK	:	Denmark,
	ES	:	Spain,
	ES	:	Spain,
	FR	:	France,
	FR	:	France,
	FR	:	France,
	MT	:	Malta,
	FI	:	Finland,
	FO	:	FaroeIs,
	GB	:	UnitedKingdom,
	GI	:	Gibraltar,
	GR	:	Greece,
	HR	:	Croatia,
	GR	:	Greece,
	GR	:	Greece,
	GR	:	Greece,
	MA	:	Morocco,
	HU	:	Hungary,
	NL	:	Netherlands,
	IT	:	Italy,
	MT	:	Malta,
	MT	:	Malta,
	IE	:	Ireland,
	IS	:	Iceland,
	LI	:	Liechtenstein,
	LU	:	Luxembourg,
	MC	:	Monaco,
	PT	:	Portugal,
	MT	:	Malta,
	NO	:	Norway,
	PL	:	Poland,
	ME	:	Montenegro,
	PT	:	Portugal,
	RO	:	Romania,
	SE	:	Sweden,
	SE	:	Sweden,
	SK	:	Slovakia,
	SM	:	SanMarino,
	CH	:	Switzerland,
	CZ	:	CzechRepublic,
	TR	:	Turkey,
	UA	:	Ukraine,
	RU	:	Russia,
	MK	:	FYRMacedonia,
	LV	:	Latvia,
	EE	:	Estonia,
	LT	:	Lithuania,
	SI	:	Slovenia,
	RS	:	Serbia,
	AI	:	Anguilla,
	US	:	USA,
	AG	:	AntiguaBarbuda,
	CW	:	Curacao,
	AW	:	Aruba,
	BS	:	Bahamas,
	BM	:	Bermuda,
	BS	:	Bahamas,
	BZ	:	Belize,
	BB	:	Barbados,
	CA	:	Canada,
	KY	:	CaymanIs,
	CR	:	CostaRica,
	CU	:	Cuba,
	DM	:	Dominica,
	DO	:	DominicanRep,
	GP	:	Guadeloupe,
	GD	:	Grenada,
	GL	:	Greenland,
	GT	:	Guatemala,
	HN	:	Honduras,
	HT	:	Haiti,
	US	:	USA,
	JM	:	Jamaica,
	KN	:	StKittsNevis,
	LC	:	StLucia,
	MX	:	Mexico,
	MQ	:	Martinique,
	MS	:	Montserrat,
	NI	:	Nicaragua,
	PA	:	Panama,
	PR	:	PuertoRico,
	SV	:	ElSalvador,
	PM	:	StPierreMiquelon,
	TT	:	TrinidadTobago,
	TC	:	TurksCaicosIs,
	US	:	USA,
	VC	:	StVincentGrenadines,
	VG	:	BritishVirginIs,
	VI	:	USVirginIs,
	AF	:	Afghanistan,
	SA	:	SaudiArabia,
	BD	:	Bangladesh,
	BH	:	Bahrain,
	BT	:	Bhutan,
	CN	:	China,
	TW	:	Taiwan,
	LK	:	SriLanka,
	IN	:	India,
	IR	:	Iran,
	AZ	:	Azerbaijan,
	IQ	:	Iraq,
	IL	:	Israel,
	JP	:	Japan,
	TM	:	Turkmenistan
	KZ	:	Kazakhstan
	UZ	:	Uzbekistan
	JO	:	Jordan
	KR	:	Korea
	PS	:	Palestine
	KP	:	DPR Korea
	KW	:	Kuwait
	LB	:	Lebanon
	KG	:	Kyrgyz Republic
	MO	:	Macao
	MV	:	Maldives
	MN	:	Mongolia
	NP	:	Nepal
	OM	:	Oman
	PK	:	Pakistan
	QA	:	Qatar
	SY	:	Syria
	AE	:	UAE
	AE	:	UAE
	TJ	:	Tajikistan
	YE	:	Yemen
	YE	:	Yemen
	HK	:	Hong Kong
	BA	:	Bosnia and Herzegovina
	AQ	:	Antarctica
	AU	:	Australia
	MM	:	Myanmar
	BN	:	Brunei
	FM	:	Micronesia
	PW	:	Palau
	NZ	:	New Zealand
	KH	:	Cambodia
	KH	:	Cambodia
	CX	:	Christmas Is
	CK	:	Cook Is
	FJ	:	Fiji
	CC	:	Cocos Is
	ID	:	Indonesia
	KI	:	Kiribati
	LA	:	Laos
	MY	:	Malaysia
	MP	:	N Mariana Is
	MH	:	Marshall Is
	NC	:	New Caledonia
	NU	:	Niue
	NR	:	Nauru
	PF	:	French Polynesia
	PH	:	Philippines
	PG	:	Papua New Guinea
	PN	:	Pitcairn Is
	SB	:	Solomon Is
	AS	:	American Samoa
	WS	:	Samoa
	SG	:	Singapore
	TH	:	Thailand
	TO	:	Tonga
	TV	:	Tuvalu
	VN	:	Vietnam
	VU	:	Vanuatu
	VU	:	Vanuatu
	WF	:	Wallis Futuna Is
	ZA	:	South Africa
	AO	:	Angola
	DZ	:	Algeria
	TF	:	St Paul Amsterdam Is
	IO	:	Ascension Is
	BI	:	Burundi
	BJ	:	Benin
	BW	:	Botswana
	CF	:	Cen Afr Rep
	CM	:	Cameroon
	CG	:	Congo
	KM	:	Comoros
	CV	:	Cape Verde
	AQ	:	Antarctica
	CI	:	Ivory Coast
	KM	:	Comoros
	DJ	:	Djibouti
	EG	:	Egypt
	ET	:	Ethiopia
	ER	:	Eritrea
	GA	:	Gabon
	GH	:	Ghana
	GM	:	Gambia
	GW	:	Guinea-Bissau
	GQ	:	Equ. Guinea
	GN	:	Guinea
	BF	:	Burkina Faso
	KE	:	Kenya
	AQ	:	Antarctica
	LR	:	Liberia
	LY	:	Libya
	LS	:	Lesotho
	MU	:	Mauritius
	MG	:	Madagascar
	ML	:	Mali
	MZ	:	Mozambique
	MR	:	Mauritania
	MW	:	Malawi
	NE	:	Niger
	NG	:	Nigeria
	NA	:	Namibia
	RE	:	Reunion
	RW	:	Rwanda
	SD	:	Sudan
	SN	:	Senegal
	SC	:	Seychelles
	SH	:	St Helena
	SO	:	Somalia
	SL	:	Sierra Leone
	ST	:	Sao Tome Principe
	SZ	:	Swaziland
	TD	:	Chad
	TG	:	Togo
	TN	:	Tunisia
	TZ	:	Tanzania
	UG	:	Uganda
	CD	:	DRCongo,
	TZ	:	Tanzania,
	ZM	:	Zambia,
	ZW	:	Zimbabwe,
	AR	:	Argentina,
	BR	:	Brazil,
	BO	:	Bolivia,
	CL	:	Chile,
	CO	:	Colombia,
	EC	:	Ecuador,
	UK	:	UK,
	GF	:	Guiana,
	GY	:	Guyana,
	PY	:	Paraguay,
	PE	:	Peru,
	SR	:	Suriname,
	UY	:	Uruguay,
	VE	:	Venezuela,
}		
*/
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		
		