export const HEADERS = [
	{label: 'MMSI', key: 'mmsi' },
	{label: 'IMO', key: 'imo' },
	{label: 'Ship ID', key: 'ship_id' },
	{label: 'Heading', key: 'heading' },
	{label: 'Nombre', key: 'shipname' },
	{label: 'Tipo', key: 'type_name'},
	{label: 'Latitud', key: 'lat'},
	{label: 'Longitud', key: 'lon'},
	{label: 'Fecha y Hora', key: 'timestamp'},
	{label: 'avg_speed', key: 'avg_speed'},
	{label: 'avg_course', key: 'avg_course'},
	{label: 'status', key: 'status'},
	{label: 'dsrc', key: 'dsrc'},
	{label: 'utc_seconds', key: 'utc_seconds'},
	{label: 'type_name', key: 'type_name'},
	{label: 'shiptype', key: 'shiptype'},
	{label: 'callsign', key: 'callsign'},
	{label: 'flag', key: 'flag'},
	{label: 'length', key: 'length'},
	{label: 'width', key: 'width'},
	{label: 'grt', key: 'grt'},
	{label: 'dwt', key: 'dwt'},
	{label: 'draught', key: 'draught'},
	{label: 'year_built', key: 'year_built'},
	{label: 'rot', key: 'rot'},
	{label: 'ais_type_summary', key: 'ais_type_summary'},
	{label: 'destination', key: 'destination'},
];