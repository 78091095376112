import {TileLayer, LayersControl} from 'react-leaflet';

const Layers = () => {
	return (
		<>
			<LayersControl position='bottomleft'>

				<LayersControl.BaseLayer name='Standard Streetmap'>
					<TileLayer 
						url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
						// attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' 
						/>
				</LayersControl.BaseLayer>

				{/* <LayersControl.BaseLayer  name='Stadia Alidade Smooth Dark'>
					<TileLayer 
						url='https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png'
						// attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors' 
						/>
				</LayersControl.BaseLayer> */}
				
				<LayersControl.BaseLayer checked name='Esri World Imagery'>
					<TileLayer 
						url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
						// attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community' 
						/>
				</LayersControl.BaseLayer>

			</LayersControl>
		</>
	)
}

export default Layers;