import styles from './styles.module.scss';

const CourseVariation = ({courseVariation, last, actual}) => {
	return (
		<div className={styles.courseVariation}>
			<p>Previo: {Math.round(last.course)}°</p>
			<p>Actual: {Math.round(actual.course)}°</p>
			<p>Variación: {Math.round(courseVariation)}°</p>
		</div>
	)
}

export default CourseVariation;