import {useState, useEffect, useContext} from 'react';
import styles from './styles.module.scss';
import axios from 'axios';
import { MapContext } from '../../../context/MapProvider';

const StatemerSettings =()=> {
	const alarmType = 'statemer';
	const alarmDescription = 'Status de AIS de emergencia';

	const {setContextData} = useContext(MapContext);
	const [configuration, setConfiguration] = useState({
		active: false, options: []	
	});

	useEffect(()=>{
		setContextData(prev=> (
			{...prev, config: {...prev.config, alarms:{...prev.config.alarms, [alarmType]: configuration}}})
		);
	},[configuration]);


	const STORAGE_API = '/data/storage';
	useEffect(()=> {
		axios.get(STORAGE_API)
			.then(r=> {
				const loadedConfig = r.data.config.alarms[alarmType];
				let active = loadedConfig.active;
				let options = Object.values(loadedConfig.options).map(v=>v);
				let configObj = {active, options};
				setConfiguration(configObj);
			})
			.catch(e=> console.log(e.message));
	}, []);

	return(
		<details className={styles.BooleanWithInputs}>
			<summary className={styles.optionName}>{alarmDescription}</summary>
			<div className={styles.options}>
				<label className={styles.activationCheckbox}>
					<input type="checkbox" 
						onClick={()=>setConfiguration({...configuration, active: !configuration.active})} 
						checked={configuration.active} />
					<span className={styles.checkboxText}>{configuration.active ? 'Activada' : 'Desactivada'}</span>
				</label>
			</div>
		</details>
	)
}

export default StatemerSettings;