import VesselsTable from './VesselsTable';
import styles from './styles.module.scss';

const VesselsReport = () => {
	return (
		<div className={styles.container}>
			<div className={styles.reports_container}>
				<VesselsTable />
			</div>
		</div>
	)
}

export default VesselsReport;