import styles from './styles.module.scss';
import { plotlyConfig } from './plotly.config';
import Plot from 'react-plotly.js';
import { getFormattedHours } from '@utils/formatTimeString';

const Graphic = ({title, data}) => {
	
	function formatTime(timeList) {
		return timeList?.map(x=> getFormattedHours(x));
	}
	

	const line = {
		x: formatTime(data?.timestamps),
		y: data?.values.y,
		name: title,
		type: "scatter",
		mode: "lines",
		line: { color: "rgba(222,192,22,1)" }
	}

	return (
		<div className={styles.graphic}>
			<Plot data={[line]} layout={{...plotlyConfig.layout, title: title}} config={plotlyConfig.config} />
		</div>
	)
}

export default Graphic;