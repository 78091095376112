import {useEffect} from 'react';
import { AiFillCheckCircle, AiFillExclamationCircle, AiFillCloseCircle } from "react-icons/ai";
import useMapContext from '@hooks/useMapContext';
import styles from './styles.module.scss';

const ICONS = {
	'Válida': <AiFillCheckCircle />,
	'Crítica': <AiFillExclamationCircle />,
	'Inválida': <AiFillCloseCircle />
};

const withTagContext =(Component)=> {
	return ({...props})=> {
		const [contextData, setContextData] = useMapContext();
		const {tagValue, tagValues, selectedIssue} = contextData;

		function setTagValue(){
			setContextData(prev=>({...prev, 
				tagValues:{...prev.tagValues, [props.data.issueId]: tagValue}}));
		}

		function getTagValue(){
			setContextData(prev=>({...prev, 
				tagValue: tagValues && tagValues[props.data.issueId]}));
		}

		useEffect(()=>{
			if (props.data.issueId === selectedIssue?.issueId) 
				setTagValue();
		}, [tagValue]);

		const interfaceElement = 
			<div className={`${styles.tag} ${tagValues && styles[tagValues[props.data.issueId]]}`}>
				{tagValues && ICONS[tagValues[props.data.issueId]]}
			</div>

		return <>
			<Component {...props} tagData={{
					value: tagValues && tagValues[props.data.issueId], 
					ICONS, 
					getTagValue,
					interfaceElement
				}}/>
		</>
	}
}

export default withTagContext;