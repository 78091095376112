import {useContext, useState, useEffect} from 'react';
import { MapContext } from './../../context/MapProvider';
import ZonesMenu from './ZonesMenu/index';
import NewContextMenu from './NewContextMenu/index';
import styles from './styles.module.scss';
import ContextButton from './../ContextButton/ContextButton';
import VesselsMenu from './VesselsMenu/index';
import {GiSattelite} from "react-icons/gi";
import axios from 'axios';
import { flyTo } from './../Map/utils';
import AlarmsMenu from './AlarmsMenu/index';

const InfoBar = () => {
	const {contextData, setContextData} = useContext(MapContext);
	const {mapRef, vessels, issues, zones} = contextData;

	const [vesselsMenu, toggleVesselsMenu] = useState(false);
	const [zonesMenu, toggleZonesMenu] = useState(false);
  const [alarmsMenu, toggleAlarmsMenu] = useState(false);

	useEffect(()=>{
		if (!zonesMenu) setContextData(prev=> ({...prev, activeZone: null}))
	},[zonesMenu])

	function onRockBlockClick() {
		const RB_API = '/rockblock';
		axios.get(RB_API)
			.then(res=>{
				const {data: hexData} = res.data[res.data.length-1];
				const textData = Buffer.from(hexData, 'hex').toString().split(',');

				function Buoy(volts, ampers, lat, lng, name='') {
					return { volts, ampers, lat: -Math.abs(lat/10000), lng: -Math.abs(lng/10000), name }
				}

				const buoy1 = new Buoy(textData[0], Math.abs(textData[1])/10, textData[2], textData[3], 'Boya 1');
				const buoy2 = new Buoy(textData[4], textData[5], textData[6], textData[7], 'Boya 2');
				
				setContextData(prev=>({...prev, Buoys: [buoy1, buoy2]}));

				const averageLocation = [(buoy1.lat + buoy2.lat)/2, (buoy1.lng + buoy2.lng)/2];
				flyTo(mapRef, null, averageLocation, 13);
			})
			.catch(err=> console.error(err.message));
	}

	return (
			<div className={styles.infoBar}>
				<div className={styles.logo}>	</div>
				<div className={styles.satteliteButton}>
					<GiSattelite onClick={onRockBlockClick}/>
				</div>
				<div className={styles.info_panel}>
					
					<div className={styles.infoItem}>
						<ContextButton onClick={()=> toggleVesselsMenu(!vesselsMenu)} />
						{
							vesselsMenu && <NewContextMenu><VesselsMenu /></NewContextMenu>
						}
						<span className={styles.text}>Naves reportando: </span> 
						<span className={styles.value}>{vessels?.length}</span>
					</div>

					<div className={styles.infoItem}>
            <ContextButton onClick={()=>toggleAlarmsMenu(prev => !prev)}/>
            {
              alarmsMenu && <NewContextMenu onClick={()=>toggleAlarmsMenu(prev => !prev)}><AlarmsMenu /></NewContextMenu>
            }
						<span className={styles.text}>Alarmas disparadas: </span> 
						<span className={styles.value}>{issues?.length}</span>
					</div>

					<div className={styles.infoItem}>
						<ContextButton onClick={()=> toggleZonesMenu(!zonesMenu)}/>
						{
							zonesMenu && <ZonesMenu/>
						}
						<span className={styles.text}>Zonas definidas: </span>
						<span className={styles.value}>{zones?.length}</span>
					</div>

				</div>
			</div>
	)
}

export default InfoBar;