export const popupContent = {
  textAlign: "center",
  height: "12rem",
	color: "white",
  fontSize: "1rem",
	margin: "0.2rem",
	padding: "0.2rem",
	borderRadius: "0"
};

export const details = {
	backgroundColor: "var(--blue-4)",
	margin: '0'
};