import { useState } from 'react';
import styles from './styles.module.scss';
import { VESSEL_TYPES } from './../../utils/data';
import MainButton from './../../UI/MainButton/MainButton';
import axios from 'axios';

const INITIAL_VALUES = {
  timestampMax: "2022-12-12T16:06:00",
  timestampMin: "2021-08-31T14:06:00",
  latMin: -48,
  latMax: -40,
  lonMin: -75,
  lonMax: -70,
  vesselType: 0,
  mmsi: null,
  status: null,
  lengthMax: null,
  lengthMin: null,
  widthMax: null,
  widthMin: null,
};

const ReportsScreen = () => {
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(INITIAL_VALUES);

  function onChangeValue(e) {
    setValues(prev => ({ ...prev, [e.target.name]: Number([e.target.value]) }));
  }

  function onChangeDateTime(e) {
    setValues(prev => ({ ...prev, [e.target.name]: [e.target.value] }));
  }

  async function onSearchClick() {
    try {
      setLoading(true);
      console.log(values);
      const r = await axios.post('https://seaguardian-bi.herokuapp.com/data/search/vessels', values);
      console.log(r.data);
      setLoading(false);
      setTableData(r.data);
    } catch(e) {
      console.error(e.message);
    }
  }

  return (
    <div className={styles.ReportsScreen}>
      <div className={styles.SideBar}>
        <MainButton
          onClick={onSearchClick}
          children={"Buscar..."} type={"save"}
        />

        <fieldset className={styles.DateTime}>
          <legend>Fecha y hora</legend>
          <div>Desde:</div>
          <input type="datetime-local"
            name={"timestampMin"}
            value={values.timestampMin}
            onChange={e => onChangeDateTime(e)}
          >
          </input>
          <div>Hasta:</div>
          <input type="datetime-local"
            name={"timestampMax"}
            value={values.timestampMax}
            onChange={e => onChangeDateTime(e)}
          ></input>
        </fieldset>

        <fieldset className={styles.Position}>
          <legend>Posición</legend>
          <div className={styles.MinMax}>
            <div>Latitud</div>
            <input type="number" placeholder="mín"
              name={"latMin"}
              value={values.latMin}
              onChange={e => onChangeValue(e)}
            />
            <input type="number" placeholder="max"
              name={"latMax"}
              value={values.latMax}
              onChange={e => onChangeValue(e)}
            />
          </div>
          <div className={styles.MinMax}>
            <div>Longitud</div>
            <input type="number" placeholder="mín"
              name={"lonMin"}
              value={values.lonMin}
              onChange={e => onChangeValue(e)}
            />
            <input type="number" placeholder="max"
              name={"lonMax"}
              value={values.lonMax}
              onChange={e => onChangeValue(e)}
            />
          </div>
        </fieldset>

        <fieldset className={styles.VesselTypes}>
          <legend>Tipo de nave</legend>
          <select
            name={"vesselType"}
            value={values.vesselType}
            onChange={e => onChangeValue(e)}>
            {
              Object.keys(VESSEL_TYPES).map((x, i) => (<option value={i}>{x}</option>))
            }
          </select>
        </fieldset>
        <fieldset>
          <legend>MMSI</legend>
          <input type="text"
            name={"mmsi"}
            value={values.mmsi}
            onChange={e => onChangeValue(e)} />
        </fieldset>
        <fieldset>
          <legend>Status</legend>
          <input type="text"
            name={"status"}
            value={values.status}
            onChange={e => onChangeValue(e)}
          />
        </fieldset>
        <fieldset className={styles.Size}>
          <legend>Tamaño</legend>
          <div className={styles.MinMax}>
            <div>Largo</div>
            <input type="number" placeholder="mín"
              name={"lengthMin"}
              value={values.lengthMin}
              onChange={e => onChangeValue(e)}
            />
            <input type="number" placeholder="max"
              name={"lengthMax"}
              value={values.lengthMax}
              onChange={e => onChangeValue(e)}
            />
          </div>
          <div className={styles.MinMax}>
            <div>Ancho</div>
            <input type="number" placeholder="mín"
              name={"widthMin"}
              value={values.widthMin}
              onChange={e => onChangeValue(e)}
            />
            <input type="number" placeholder="max"
              name={"widthMax"}
              value={values.widthMax}
              onChange={e => onChangeValue(e)}
            />
          </div>
        </fieldset>

        <MainButton
          onClick={onSearchClick}
          children={"Buscar..."} type={"save"}
        />

      </div>
      <div className={styles.ResultsContainer}>
        <table>
          <thead>
            <tr>
              <td>MMSI</td>
              <td>IMO</td>
              <td>Nombre</td>
              <td>Tipo</td>
              <td>Callsign</td>
              <td>Bandera</td>
              <td>Longitud</td>
              <td>Ancho</td>
              <td>GRT</td>
              <td>Calado</td>
              <td>Tipo (nonmbre)</td>
              <td>Fecha&Hora</td>
              <td>Latitud</td>
              <td>Longitud</td>
              <td>Velocidad</td>
              <td>Rumbo</td>
              <td>Status</td>
            </tr>
          </thead>
          <tbody>
            {
              loading ? <tr>
                <td colspan="17" className={styles.loadingCell}>Base de datos histórica no disponible.</td>
              </tr>
                :
                tableData?.map(x => (
                  <tr>
                    <td>{x?.mmsi}</td>
                    <td>{x?.imo}</td>
                    <td>{x?.shipname}</td>
                    <td>{x?.shiptype}</td>
                    <td>{x?.callsign}</td>
                    <td>{x?.flag}</td>
                    <td>{x?.length}</td>
                    <td>{x?.width}</td>
                    <td>{x?.grt}</td>
                    <td>{x?.draught}</td>
                    <td>{x?.type_name}</td>
                    <td>{x?.timestamp}</td>
                    <td>{x?.lat}</td>
                    <td>{x?.lon}</td>
                    <td>{x?.avg_speed}</td>
                    <td>{x?.avg_course}</td>
                    <td>{x?.status}</td>
                  </tr>
                ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReportsScreen;