import { AiFillCheckCircle, AiFillCloseCircle, AiFillExclamationCircle } from "react-icons/ai";
import useMapContext from './../../../../../hooks/useMapContext';
import styles from './styles.module.scss';
import {useEffect} from 'react';

const ValidityOptions = () => {
	const [contextData, setContextData] = useMapContext();
	const {tagValue} = contextData;
	
	function onValidityClick(option) {
		setContextData(prev=> ({...prev, tagValue: option}))
	}

	useEffect(()=>{}, [tagValue]);

	return(
		<div className={styles.validity_options}>
			<label onClick={()=> onValidityClick('Válida')} 
				className={`${styles.valid} ${tagValue==='Válida' && styles.active}`}>
				<AiFillCheckCircle />
				Válida
			</label>
			<label onClick={()=> onValidityClick('Crítica')} 
				className={`${styles.critic} ${tagValue==='Crítica' && styles.active}`}>
				<AiFillExclamationCircle />
				Crítica
			</label>
			<label onClick={()=> onValidityClick('Inválida')} 
				className={`${styles.invalid} ${tagValue==='Inválida' && styles.active}`}>
				<AiFillCloseCircle />
				Inválida
			</label>
		</div>
	)
}

export default ValidityOptions;