import {useState, useEffect, useContext} from 'react';
import styles from './styles.module.scss';
import axios from 'axios';
import { MapContext } from '../../../context/MapProvider';

const AisnotSettings =()=> {
	const alarmType = 'aisnot';
	const alarmDescription = 'Pérdida de Señal AIS';

	const {setContextData} = useContext(MapContext);
	const [configuration, setConfiguration] = useState({
		active: false, options: []	
	});

	useEffect(()=>{
		setContextData(prev=> (
			{...prev, config: {...prev.config, alarms:{...prev.config.alarms, [alarmType]: configuration}}})
		);
	},[configuration]);

	const OPTIONS = [
		'Tiempo',
	];

	const STORAGE_API = '/data/storage';
	useEffect(()=> {
		axios.get(STORAGE_API)
			.then(r=> {
				const loadedConfig = r.data.config.alarms[alarmType];
				let active = loadedConfig.active;
				let options = loadedConfig.options && Object.values(loadedConfig.options).map(v=>v);
				let configObj = {active, options};
				setConfiguration(configObj);
			})
			.catch(e=> console.log(e.message));
	}, []);

	return(
		<details className={styles.BooleanWithInputs}>
			<summary className={styles.optionName}>{alarmDescription}</summary>
			<div className={styles.options}>
				<label className={styles.activationCheckbox}>
					<input type="checkbox" 
						onClick={()=>setConfiguration({...configuration, active: !configuration.active})} 
						checked={configuration.active} />
					<span className={styles.checkboxText}>{configuration.active ? 'Activada' : 'Desactivada'}</span>
				</label>

				<div className={styles.inputSet}>
				{
					OPTIONS.map((x,i)=> (
						<label key={x}>
							<span>{x}</span>
							<input type="number" name={x} id={i} 
								value={configuration.options[i]} 
								onChange={(e)=>{
									const newOptions = [...configuration.options];
									newOptions[i] = parseInt(e.target.value);
									setConfiguration({...configuration, options: [...newOptions]});
								}} />
						</label>))
				}
				</div>
			</div>
		</details>
	)
}

export default AisnotSettings;