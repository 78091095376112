import {AiOutlineAreaChart} from "react-icons/ai";
import {FaRoute} from "react-icons/fa";
import styles from './styles.module.scss';

const ToolbarButton = ({icon, title, color, onClick, status}) => {
	const ICONS = {
		'AiOutlineAreaChart': <AiOutlineAreaChart  className={styles.menuIcon}/>,
		'FaRoute': <FaRoute className={styles.menuIcon} />
	}

	return (
		<div className={`${styles.toolbarButton} ${styles[color]} ${styles[status.toString()]}`} onClick={onClick}>
			{ICONS[icon]}
			<span>{title}</span>
		</div>
	)
}

export default ToolbarButton;