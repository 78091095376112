import {useState, useEffect, useContext} from 'react'
import {getShipIcon} from './ship-icons';
import Ship from './Ship';
import PopupCard from './PopupCard';
import './styles.css';
import { MapContext } from './../../context/MapProvider';
import {Tooltip} from 'react-leaflet';

const Ships = ({data}) => {
	const [popupRefs, setPopupRefs] = useState({});
	const {contextData, setContextData} = useContext(MapContext);
	const {shipIconSize, shipsFilter} = contextData;

	useEffect(()=>{
		setContextData(prev=>({...prev, popupRefs: popupRefs}))
	}, [popupRefs]);

	if (!data.length) return <></>;

	return (
		<>
			{
				data?.map(ship => {
					const {lat, lon, course} = ship;
					
					let vesselColor = 0;
					if (ship.vessel_type > 8 || !ship.vessel_type) {
						vesselColor = 0;
					} else {
						vesselColor = ship.vessel_type;
					}

					
					if (shipsFilter.includes(Number(ship.vessel_type)))
					return (
						<Ship 
							position={[lat, lon]} 
							rotationAngle={course} 
							icon={getShipIcon(vesselColor, shipIconSize)}
							data={ship}
							setPopupRefs={setPopupRefs}
						>
							<PopupCard data={ship} />

							<Tooltip direction="top" offset={[0, 0]} opacity={1}>
								<div className="tooltip">
									<p>Nombre: {ship?.shipname}</p> 
									<p>MMSI: {ship?.mmsi}</p>
								</div>
							</Tooltip>

						</Ship>
					)
				})
			}
		</>
	)
}

export default Ships;