import ShipsList from './../../../../components/ShipsList/index';
import axios from 'axios';
import AlarmInfo from './AlarmInfo';
import styles from './styles.module.scss';
import ValidityOptions from './ValidityOptions';
import useMapContext from './../../../../hooks/useMapContext';

const AlarmDetail = () => {
	const [contextData] = useMapContext();
	const {selectedIssue} = contextData;
	
	return (
		<div className={styles.alarmDetail}>
			{selectedIssue && (<>
				<LeftPanel />
				<RightPanel />
			</>)}
		</div>
	);
}

export default AlarmDetail;

const LeftPanel =()=> {
	return(
		<div className={styles.left_panel}>
			<AlarmInfo />
			<Buttons />
			<ValidityOptions />
		</div>
	)
}

const RightPanel =()=> {
	return(
		<div className={styles.right_panel}>
			Naves:
			<ShipsList />
		</div>
	)
}

const Buttons =()=> {
	const [contextData, setContextData] = useMapContext();
	const {selectedIssue} = contextData;

	function onClose() {
		axios.put(`/issue/remove/${selectedIssue?.issueId}`)
			.then(()=>{
				setContextData(prev=> ({...prev, issues: prev.issues.filter(x=> x.issueId !== selectedIssue?.issueId)}));
			})
			.catch((err)=> console.error(err.message));
	}

	return(
		<div className={styles.buttons}>
			<div className={`${styles.close} ${styles.button}`} onClick={onClose}>
				Cerrar
			</div>
		</div>
	)
}