import ZonesBar2 from './ZonesBar2';
import styles from '../styles.module.scss';

const LocationsAndZonesBar = () => {
	return (
		<div className={styles.markers_bar}>
			<ZonesBar2 />
		</div>
	)
}

export default LocationsAndZonesBar;