import {useState, useEffect, useContext, useRef} from 'react';
import { MapContext } from '@context/MapProvider';
import axios from 'axios';
import L from 'leaflet';
import uuid from 'react-uuid';
import ZonesList from './ZonesList';
import ColorsPanel from './ColorsPanel';
import NamePanel from './NamePanel';
import circleMarker from './circleMarker.svg';
import ShipIdsPanel from './ShipIdsPanel';
import { VESSEL_TYPES } from '../../../../utils/data';
import MiniBooleanSet from './MiniBooleanSet/index';
import ZoneMode from './ZoneMode/index';
import IssueType from './IssueType/index';
import { vesselDescriptionsToIds, ISSUE_TYPES } from './utils';
import MainButton from './../../../../UI/MainButton/MainButton';
import styles from './styles.module.scss';

const markerIcon= L.icon({ iconUrl: circleMarker, iconSize: [20, 20] });
const markerOptions = {draggable: true, icon: markerIcon };

const ZonesBar2 = () => {
	// Context and state --- ____________________________________________________
	const {contextData, setContextData} = useContext(MapContext);
	const {
		mapRef, clickLocation, linesColor, zoneMarkers, 
		vesselsInZone, vesselTypesInZone, zoneIssue, zoneMode, issueTypes, drawing,
	} = contextData;
	const {lat: LAT, lng: LNG} = clickLocation;
	
	const [name, setName] = useState('');
	
	// Clean map off markers --- ________________________________________________
	function cleanOffMarkers(){
		zoneMarkers?.map(m => m.remove());
		mapRef?.removeLayer(zoneMarkers);
		zoneMarkers.length = 0;
	}
	useEffect(()=>{
		return ()=>{
			cleanOffMarkers();
		}
	}, []);
	
	// Mutable ref for events --- _______________________________________________
	const globalLines = useRef(contextData.lines);
	useEffect(()=>{
		globalLines.current = contextData.lines
	}, [contextData.lines]);

	// Defining zones --- _______________________________________________________
	function CreateNewZone(name, color, coordinates, vesselsInfo, vesselTypes, issue, mode, visible, issueTypes, zoneMarkers, deleble=true) {
		return {
			zoneId: uuid(), 
			name, 
			color, 
			geometry: {coordinates}, 
			vesselsInfo, 
			vesselTypes, 
			issue, 
			mode, 
			visible,
			issueTypes,
			zoneMarkers,
			deleble
		}
	}

	useEffect(()=>{
		if (!drawing) {
			zoneMarkers.map(m => m.remove());
			setName('');
			setZoneMarkers([]);
			setContextData(prev=>({...prev, lines: []}));
		}
		const readyToSave = !drawing && zoneMarkers.length > 2 && name;
								
		if(readyToSave) {
			const newZone = CreateNewZone(
				name, linesColor, getCoordinates(), 
				vesselsInZone.map(x=>({id: x?.vesselId, mmsi: x?.mmsi, name: x?.shipname})), 
				vesselDescriptionsToIds(vesselTypesInZone), 
				zoneIssue, zoneMode, false, issueTypes, zoneMarkers, true);
				if (!contextData.zones) setContextData(prev=>({...prev, zones: []}));
			setContextData(prev=>({...prev, zones: [...contextData.zones, newZone]}));
			
      // Save zone to DB ---
      (async()=>{
        try {
          axios.post('/zone/set/polygon', newZone);
        } catch(e) {
          console.error(e.message)
        }
      })();
		}
	}, [drawing]);


	// Adding markers and events --- ____________________________________________
	const setZoneMarkers =(markers)=>{
		setContextData(prev=>({...prev, zoneMarkers: markers}));
	}
	useEffect(()=>{
		const setHoveredMarker =(id)=> {
			setContextData(prev=>({...prev, hoveredMarker: id}));
		}
		const setSelectedMarker =(id)=> {
			setContextData(prev=>({...prev, selectedMarker: id}));
		}

		if(clickLocation.lat && drawing) {
			let initialPos;
			let newMarker = L.marker([LAT, LNG], markerOptions)
				.on('mousedown', ()=> {
					initialPos = [newMarker.getLatLng().lat, newMarker.getLatLng().lng];
				})
				.on('dragend', ()=> {
					let finalPos = [newMarker.getLatLng().lat, newMarker.getLatLng().lng];
					let newLines = globalLines.current.map(x => {
						if (x[0] === initialPos[0] && x[1] === initialPos[1]) {
							return finalPos;
						} else return x;
					});
					setContextData(prev=>({...prev, lines: newLines}));
				})
				.on('click', ()=> {setSelectedMarker(newMarker._leaflet_id)})
				.on('mouseover', ()=> {setHoveredMarker(newMarker._leaflet_id)})
				.on('mouseout', ()=> {setHoveredMarker()})
				.addTo(mapRef);

				setZoneMarkers([...zoneMarkers, newMarker]);	
		}
	}, [clickLocation]);

	// Polygon data to render while user is drawing ---	_________________________
	const getCoordinates =()=> zoneMarkers?.map(m => ([m.getLatLng().lat, m.getLatLng().lng]));
	useEffect(()=>{
		setContextData(prevContext=>({...prevContext, lines: getCoordinates()}));
	}, [zoneMarkers]);
	
	function onSaveButtonClick(){
		if (
			(drawing && name && zoneMarkers.length > 2)
			||
			(!drawing)
		)
			setContextData(prev=>({...prev, drawing: !prev.drawing}));
	}

	function onCancelButtonClick(){
		cleanOffMarkers();
		setContextData(prev=>({...prev, drawing: false}));
	}

	return (
		<div className={styles.zonesbar} >
			<div className={`${styles.newMarkerPanel} ${!drawing && styles.invisible}`}>
				<ColorsPanel />
				<NamePanel name={name} setName={setName} />
				<div className={styles.selectionsPanel}>
					<ZoneMode />
					{
						zoneMode !== 'inactive' && <IssueType />
					}
				</div>
				{
					zoneMode !== 'inactive' && (<>
						<MiniBooleanSet legend={'Tipos de nave'} options={VESSEL_TYPES} 
							contextKey={'vesselTypesInZone'}
							/>
						<ShipIdsPanel />
					</>)
				}
				{
					zoneMode === 'inactive' && <MiniBooleanSet legend={'Tipos de alerta'} options={ISSUE_TYPES} 
						contextKey={'issueTypes'}
					/>
				}
			</div>
			<div className={styles.mainButtons}>
				{drawing && <MainButton onClick={onCancelButtonClick} type={'cancel'}>
					Cancelar
				</MainButton>}
				<MainButton onClick={onSaveButtonClick} type={'save'}>
					{!drawing ? 'Nueva Zona' : 'Guardar'}
				</MainButton>
			</div>

			{!drawing && <ZonesList />}
		</div>
	)
}

export default ZonesBar2;