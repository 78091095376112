import { MapContext } from '../../context/MapProvider';
import { useContext } from 'react';
import { CSVLink } from "react-csv";
import {HEADERS} from './utils';
import styles from './styles.module.scss';

const Searchbar = () => {
	const {contextData} = useContext(MapContext);
	const {vessels} = contextData;
	
	return (
		<div className={styles.searchbar}>
			<label>
			<CSVLink data={vessels} headers={HEADERS}>		
				<button 
					style={{padding: '0.5rem', backgroundColor: 'var(--blue-4)', cursor: 'pointer', color: 'white', border: '1px solid var(--blue-1)', outline: 'none', borderRadius: '5px'}}>
						Exportar .csv
				</button>
			</CSVLink>
			</label>
		</div>
	)
}

export default Searchbar;