import { useContext } from 'react';
import Searchbar from './Searchbar';
import { MapContext } from '@context/MapProvider';
import styles from './styles.module.scss';
import { formatTimeString } from '@utils/formatTimeString';
import { normalizeLatLng } from '@utils/normalizeLatLng';
import { toShortName, getIcon } from '@utils/issueTypes';


const AlertsTable = () => {
  const { contextData } = useContext(MapContext);
  const { vessels, issues } = contextData;

  function getMMSI(vesselId) {
    const vessel =  vessels.find(ship => ship.vesselId == vesselId);
    return vessel?.mmsi;
  }

  if (!vessels.length || !issues.length) return <>Esperando datos...</>

  return (
    <div className={styles.alarms_list}>
      <h2>Alertas del sistema</h2>
      <Searchbar />
      <table style={{textAlign: 'center'}}>
        <thead>
          <tr>
            <td>ID de alerta</td>
            <td>Posición</td>
            <td>Nombre</td>
            <td>Score</td>
            <td>Threshold</td>
            <td>Timestamp</td>
            <td>MMSI de la nave</td>
          </tr>
        </thead>
        <tbody>
          {
            issues?.map(({ issueId, lat, lon, name, score, threshold, timestamp, vesselId, }) =>
              <tr key={issues.issueId}>
                <td>{issueId}</td>
                <td>{normalizeLatLng([lat, lon])}</td>
                <td style={{display: 'flex', alignItems: 'center', marginLeft: '1.5em'}}><div className={styles.icon}>{getIcon(name)}</div><div>{toShortName(name)}</div></td>
                <td>{score}</td>
                <td>{threshold ? threshold : '---'}</td>
                <td>{formatTimeString(timestamp)}</td>
                <td>{getMMSI(vesselId)}</td>
              </tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
};

export default AlertsTable;