const MONTHS = [
  'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
];

export function formatTimeString(timestamp) {
	const date = new Date(timestamp);
	let dateString = '';
	let minutes = date.getMinutes();
	if (minutes < 10) minutes='0'+minutes;
	if(timestamp) dateString = `${date.getDate()} / ${MONTHS[date.getMonth()]} / ${date.getFullYear()} - ${date.getHours()}:${minutes}`;
	return dateString;
}

export function getFormattedHours(timestamp) {
	const date = new Date(timestamp);
	let dateString = '';
	let minutes = date.getMinutes();
	if (minutes < 10) minutes='0'+minutes;
	if(timestamp) dateString = `${date.getHours()}:${minutes}`;
	return dateString;
}

