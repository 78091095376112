import styles from './styles.module.scss';
import { useContext } from 'react';
import { MapContext } from './../../../context/MapProvider';
import axios from 'axios';

const AlarmsMenu = () => {
  const { setContextData } = useContext(MapContext);

  async function onCloseAllAlertsClick() {
    try {
      axios.post('/issue/removeAllIssues');
      setContextData(prev => ({ ...prev, issues: [] }));
    } catch (e) {
      console.error(e.message);
    }
  }

  return (
    <div className={styles.alarmsMenu}>
      <ul>
        <li onClick={onCloseAllAlertsClick}>Cerrar todas</li>
      </ul>
    </div>
  );
};

export default AlarmsMenu;