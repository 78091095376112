import Map from '@components/Map/index';
import GoTo from '@components/GoTo';
import CursorLocation from '@components/CursorLocation';
import styles from './styles.module.scss';

const MapSection = () => {
	return (
		<div className={styles.mapSection}>
			<div className={styles.toolbar}>
				<GoTo />
				<CursorLocation />
			</div>
			<div className={styles.map}>
				<Map />
			</div>
		</div>
	)
}

export default MapSection;