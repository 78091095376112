import AlertsTable from './AlertsTable';
import styles from './styles.module.scss';

const AlarmsReport = () => {
	return (
		<div className={styles.container}>
			<div className={styles.reports_container}>
				<AlertsTable />
			</div>
		</div>
	)
}

export default AlarmsReport;