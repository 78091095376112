import {useContext} from 'react';
import { MapContext } from '@context/MapProvider';
import styles from './styles.module.scss';

const ZoneMode = () => {
	const {setContextData} = useContext(MapContext);

	return (
		<fieldset className={styles.zoneMode}>
			<legend>Modo de zona</legend>
			<select onChange={(e)=> setContextData(prev=>({...prev, zoneMode: e.target.value}))}>
				<option value="active">Activa</option>
				<option value="inactive">Inactiva</option>
			</select>			
		</fieldset>
	)
}

export default ZoneMode;