import styles from './styles.module.scss';
import { formatTimeString } from '@utils/formatTimeString';

const AisNot = ({lastReportTime}) => {
	return (
		<div className={styles.aisNot}>
			<p>Último reporte: {formatTimeString(lastReportTime)}</p>
		</div>
	)
}

export default AisNot;