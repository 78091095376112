import { MapContext } from '@context/MapProvider';
import { useContext } from 'react';
import styles from './styles.module.scss';

const SizesMenu = () => {
	const {setContextData} = useContext(MapContext);

	function setSize(size) {
		setContextData(prev=> ({...prev, shipIconSize: size}));
	}

	return (
		<div className={styles.SizesMenu}>
			<ul>
				<li onClick={()=>setSize(0)}>Muy pequeño</li>
				<li onClick={()=>setSize(1)}>Pequeño</li>
				<li onClick={()=>setSize(2)}>Mediano</li>
				<li onClick={()=>setSize(3)}>Grande</li>
				<li onClick={()=>setSize(4)}>Muy Grande</li>
			</ul>
		</div>
	)
}

export default SizesMenu;