import {useContext} from 'react'
import { MapContext } from '@context/MapProvider';
import styles from './styles.module.scss';

const CursorLocation = () => {
	const {contextData} = useContext(MapContext);
	const {cursorLocation} = contextData;
	const precision = 6;
	const lat = cursorLocation?.lat?.toPrecision(precision);
	const lng = cursorLocation?.lng?.toPrecision(precision);

	return (
		<div className={styles.cursorLocation}>
			Cursor: [{lat}, {lng}]
		</div>
	)
}

export default CursorLocation;