import { NavLink } from 'react-router-dom';
import { GrDashboard, GrDocumentText, GrMap, GrSettingsOption } from "react-icons/gr";
import { AiTwotoneAlert } from "react-icons/ai";
import { SHIP_ICONS } from './../Ships/ship-icons';
import styles from "./styles.module.scss";

const Footbar = () => {
  return (
		<div className={styles.footbar}>
				<NavLink exact to='/settings' activeClassName={styles.activeNavLink}><GrSettingsOption/> Configuración</NavLink>
				<NavLink exact to='/reports-screen' activeClassName={styles.activeNavLink}><GrDocumentText/>  Reportes</NavLink>
				<NavLink exact to='/vessels-report' activeClassName={styles.activeNavLink}>{SHIP_ICONS[1]}  Naves</NavLink>
				<NavLink exact to='/alarms-report' activeClassName={styles.activeNavLink}><AiTwotoneAlert /> Alarmas</NavLink>
				<NavLink exact to='/' activeClassName={styles.activeNavLink}><GrDashboard/>  Tablero Principal</NavLink>
				<NavLink exact to='/map-screen' activeClassName={styles.activeNavLink}><GrMap/>  Zonas</NavLink>
    </div>
  );
};

export default Footbar;