import {useState, useContext, useEffect} from 'react';
import { ZoneInfo } from '../ZoneInfo';
import { AiOutlineEdit, AiOutlineSave, AiOutlineDelete } from "react-icons/ai";
import { BiDuplicate } from "react-icons/bi";
import axios from 'axios';
import { MapContext } from '@context/MapProvider';
import styles from './styles.module.scss';
import { flyTo } from './../../../../../components/Map/utils';

const ZonesList = () => {
	const {contextData, setContextData} = useContext(MapContext);
	const {mapRef, zones, activeZone} = contextData;
	const [visibleZones, setVisibleZones] = useState({});

	useEffect(()=>{
		const localStorageObject = localStorage.getItem('visibleZones');
		if (localStorage.getItem('visibleZones')) setVisibleZones(JSON.parse(localStorageObject));
	},[]);

	useEffect(()=>{setContextData(prev=>({...prev, visibleZones}))},[visibleZones])

	function onToggleZoneDisplayClick(zone, e) {
		const hideZone = () => {
			setContextData(prev=>({...prev, activeZone: null}))
		}
		const showSelectedZone = (zone) => {
			setContextData(prev=>({...prev, activeZone: zone,}));
			flyTo(mapRef, null, zone.geometry.coordinates[0],10);
		}
		
		if (e.target.localName === 'span' && e.target.id === 'name')
		{
			if(activeZone?.geometry?.coordinates?.length && zone?.zoneId === activeZone?.zoneId) {
				hideZone();
			} else {
				if (e.target.localName !=='button')	showSelectedZone(zone);
			}
		}
	}

	function onEditClick(zoneId){
		setContextData(prev=> ({...prev, 
			activeSidebar: true,
			drawing: true,
			lines: prev.activeZone.geometry.coordinates,
			zoneMarkers: prev.activeZone.zoneMarkers
		}))
	}

	function onDeleteClick(zoneId) {
		setContextData(prev=>({...prev, activeZone: null, lines: [], zones: prev.zones.filter(x=>x.zoneId !== zoneId)}));
		axios.delete(`/zone/delete/polygon/${zoneId}`)
	}

	function onSwitchVisible() {
		activeZone.visible = !activeZone.visible;
		setVisibleZones(prev=>({...prev, [activeZone.zoneId]: activeZone.visible}));
		const storageObject = JSON.stringify({...visibleZones, [activeZone?.zoneId]: activeZone.visible});
		localStorage.setItem('visibleZones', storageObject);
	}

	return !zones ? 'Loading...'
	:
	(
		<div className={styles.zonesList} >
			{
				zones?.map(zone => (
					<div className={styles.zoneItem} 
						onClick={(e)=> onToggleZoneDisplayClick(zone, e)}
						style={{borderLeft: `0.4vw solid ${zone.color}`}}
						key={zone.zoneId}
						>
						<div className={styles.heading}>
							<span className={styles.name} id={'name'}>{zone.name}</span>
							{
								zone.deleble && activeZone && activeZone.zoneId === zone.zoneId && (<>
									{/* <button className={styles.editButton} onClick={()=> onEditClick(zone?.zoneId)} id={'button'}>
										<BiDuplicate />
									</button> */}
									<button className={styles.deleteButton} onClick={()=> onDeleteClick(zone?.zoneId)} id={'button'}>
										<AiOutlineDelete />
									</button>
								</>)
							}
						</div>
						{
							activeZone?.zoneId === zone?.zoneId && 
							(<>
								<div className={styles.toolbar} >
									<label>
										<input type="checkbox" 
											checked={visibleZones[activeZone?.zoneId]}
											onClick={onSwitchVisible}
										/>
										<span>Mantener visible en mapa</span>
									</label>
								</div>
								<ZoneInfo />
							</>)
						}
					</div>
				))
			}
		</div>
	)
}

export default ZonesList;