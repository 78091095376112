import {useContext} from 'react';
import { MapContext } from '@context/MapProvider';
import { getFlag } from '@images/flags/data'
import styles from './styles.module.scss';
import ToolbarButton from './ToolbarButton/index';

const HeadingBar = () => {
	const {contextData, setContextData} = useContext(MapContext);
	const {selectedVesselInfo, graphicsOn, routesOn} = contextData;
	
	function toggleGraphics() {
		setContextData(prev=>({...prev, graphicsOn: !prev.graphicsOn}));
	}

	function toggleRoutes() {
		setContextData(prev=>({...prev, routesOn: !prev.routesOn}));
	}

	return (
		<div className={styles.heading}>
			<div>
				<ToolbarButton 
					icon={'AiOutlineAreaChart'} 
					title={'Gráficos'} 
					color={'blue'} 
					status={graphicsOn} 
					onClick={toggleGraphics} 
				/>
				<ToolbarButton 
					icon={'FaRoute'} 
					title={'Rutas'} 
					color={'yellow'} 
					status={routesOn} 
					onClick={toggleRoutes} 
				/>
			</div>

			<div className={styles.name}>{selectedVesselInfo?.shipname ? selectedVesselInfo?.shipname : '---'}</div>
			<div className={styles.imgContainer}>
				{
					getFlag(selectedVesselInfo?.flag) && <img src={getFlag(selectedVesselInfo?.flag)} alt="Bandera" />
				}
			</div>
			<div className={styles.ids}>
				<div>MMSI: {selectedVesselInfo?.mmsi ? selectedVesselInfo?.mmsi : '---'}</div>
				<div>IMO: {selectedVesselInfo?.imo  ? selectedVesselInfo?.imo : '---'}</div>
				<div>CS: {selectedVesselInfo?.callsign  ? selectedVesselInfo?.callsign : '---'}</div>
			</div>
		
		</div>
	)
}

export default HeadingBar;