import {useState, useEffect, useContext} from 'react';
import styles from './styles.module.scss';
import axios from 'axios';
import { MapContext } from './../../../context/MapProvider';

const HideOptions =()=> {
	const {setContextData} = useContext(MapContext);
	const [configuration, setConfiguration] = useState({});

	useEffect(()=>{
		setContextData(prev=> (
			{...prev, config: {...prev.config, doNotShow: configuration}})
		);
	},[configuration]);


	const STORAGE_API = '/data/storage';
	useEffect(()=> {
		axios.get(STORAGE_API)
			.then(r=> {
				const loadedConfig = r.data.config.doNotShow;
				let configObj = {...loadedConfig};
				setConfiguration(configObj);
			})
			.catch(e=> console.log(e.message));
	}, []);

	return(
		<div className={styles.HideOptions}>
			<fieldset>
				<legend>{'Tiempos para dejar de mostrar (horas)'}</legend>
				<label>
					<span>Alertas</span>
					<input type="number"
						value={configuration?.doNotShowTimeIssues} 
						onChange={(e)=>{
							setConfiguration({...configuration, doNotShowTimeIssues: parseInt(e.target.value)})
						}}/>
				</label>
				<label>
					<span>Naves</span>
					<input type="number" 
						value={configuration?.doNotShowTimeVessels} 
						onChange={(e)=>{
							setConfiguration({...configuration, doNotShowTimeVessels: parseInt(e.target.value)})
						}}/>
				</label>
			</fieldset>
		</div>
	)
}

export default HideOptions;