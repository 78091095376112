import { MapContext } from '@context/MapProvider';
import { useContext, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const useConfig =()=> {
	const {contextData, setContextData} = useContext(MapContext);
	const {alarms, paramsPriorizator, doNotShow} = contextData.config;
	const [response] = useState();
	const notify = (message) => toast(message);

	function setConfig(config){
		setContextData(prev=>({...prev, config: {...prev.config, ...config}}));
		const configObject = {
			systemConfig: {
				doNotShow,
				paramsPriorizator,
				alarms,
			}
		};
		
		axios.post('/config/global', configObject)
			.then(r=> {
				notify(r.data.message);
				console.log(r.data);

			})
			.catch(err=> console.error)
	}

	return [contextData.config, setConfig, response];
}

export default useConfig;