import {ReactComponent as Proximity} from '../../../icons/alerts/proximity.svg';
import {ReactComponent as AisYes} from '../../../icons/alerts/aisyes.svg';
import {ReactComponent as AisNot} from '../../../icons/alerts/aisnot.svg';
import {ReactComponent as SpeedZero} from '../../../icons/alerts/speed0.svg';
import {ReactComponent as SpeedVar} from '../../../icons/alerts/speedvar.svg';
import {ReactComponent as Risk} from '../../../icons/alerts/risk.svg';
import {ReactComponent as CourseVar} from '../../../icons/alerts/coursevar.svg';

export const ISSUE_TYPES = [
	{alarmCode: '0', keyword: 'proximity', shortName: 'Naves muy cerca', description: '', icon: <Proximity />, color: 'white'},
	{alarmCode: '1', keyword: 'restricted', shortName: 'Zona restringida', description: '', icon: <Risk />, color: 'white'},
	{alarmCode: '2', keyword: 'risk', shortName: 'Zona peligro', description: '', icon: <Risk />, color: 'white'},
	{alarmCode: '4', keyword: 'aisnot', shortName: 'Pérdida señal AIS', description: '', icon: <AisNot />, color: 'white'},
	{alarmCode: '5', keyword: 'aisyes', shortName: 'Nueva señal AIS', description: '', icon: <AisYes/>, color: 'white'},
	{alarmCode: '6', keyword: 'speedvar', shortName: 'Variación velocidad', description: '', icon: <SpeedVar />, color: 'white'},
	{alarmCode: '7', keyword: 'stopped', shortName: 'Nave detenida', description: '', icon: <SpeedZero />, color: 'white'},
	{alarmCode: '8', keyword: 'coursevar', shortName: 'Variación rumbo', description: '', icon: <CourseVar />, color: 'white'},
	{alarmCode: '9', keyword: 'speedran', shortName: 'Rango velocidad', description: '', icon: <SpeedVar />, color: 'white'},
	{alarmCode: '10', keyword:'statchan', shortName: 'Cambio Status', description: '', icon: <SpeedVar />, color: 'white'},
  {alarmCode: '11', keyword:'statemer', shortName: 'Status Emergencia', description: '', icon: <SpeedVar />, color: 'white'},
];

export function toShortName(keyword) {
	return ISSUE_TYPES.find(x => x.keyword === keyword)?.shortName;
}

export function getIcon(keyword) {
	return ISSUE_TYPES.find(x => x.keyword === keyword)?.icon;
}

export function getColor(keyword) {
	return ISSUE_TYPES.find(x => x.keyword === keyword)?.color;
}