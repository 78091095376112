import {useContext} from 'react';
import styles from './styles.module.scss';
import LocationsAndZonesBar from './Sidebar/LocationsAndZonesBar';
import Map from '../../components/Map';
import MarkersPanel from './MarkersPanel';
import {MapContext} from '../../context/MapProvider';

const MapScreen = () => {
	const {contextData} = useContext(MapContext);
	const {zoneMarkers, hoveredMarker, selectedMarker, activeSidebar, drawing} = contextData;

	return (
		<div className={styles.MapScreen}>
			{activeSidebar && <LocationsAndZonesBar />}
			<Map />
			{drawing && <MarkersPanel markers={zoneMarkers} hoveredMarker={hoveredMarker} selectedMarker={selectedMarker} />}
		</div>
	) 	
}

export default MapScreen;