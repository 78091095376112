import {MapContext} from '@context/MapProvider';
import {useContext} from 'react';
import styles from './styles.module.scss';

const ColorsPanel = () => {
	const {contextData, setContextData} = useContext(MapContext);
	const {linesColor} = contextData;

	function setColor(color) {
		setContextData(prev=>({...prev, linesColor: color}));
	}

	const COLORS = ['red', 'green', 'blue', 'yellow', 'white', 'black'];
	return (
		<fieldset className={styles.colorsPanel}>
			<legend>Color del área</legend>
			{
				COLORS.map(c => (
					<div className={`${styles[c]} ${linesColor===c && styles.active}`}
						onClick={()=> setColor(c)} />
				))
			}
		{/* 					
			<div className={`${styles.red} ${linesColor==='red' && styles.active}`} onClick={()=> setColor('red')} />
			<div className={`${styles.green} ${linesColor==='green' && styles.active}`} onClick={()=> setColor('green')} />
			<div className={`${styles.blue} ${linesColor==='blue' && styles.active}`} onClick={()=> setColor('blue')} />
			<div className={`${styles.yellow} ${linesColor==='yellow' && styles.active}`} onClick={()=> setColor('yellow')} />
			<div className={`${styles.white} ${linesColor==='white' && styles.active}`} onClick={()=> setColor('white')} />
			<div className={`${styles.black} ${linesColor==='black' && styles.active}`} onClick={()=> setColor('black')} /> 
		*/}
		</fieldset>
	)
}

export default ColorsPanel;