import styles from './styles.module.scss';
import { useRef } from 'react';

const LoginScreen = ({ setAuth }) => {
	let user = useRef();
	let pass = useRef();

	const loginUser = process.env.REACT_APP_USER;
	const loginPass = process.env.REACT_APP_PASS;

	
	function onClick() {
		if (
			(user.current.value === loginUser)
			&&
			(pass.current.value === loginPass)
		 ) {
				setAuth(true);
		}
	}

		return (
			<div className={styles.LoginScreen}>
				<div className={styles.loginBox}>
					<label>
						<div>Usuario:</div>
						<input type="text" ref={user}
						/>
					</label>
					<label>
						<div>Contraseña:</div>
						<input type="password" ref={pass}
						/>
					</label>
					<div className={styles.button} onClick={onClick}>
						Ingresar
					</div>
				</div>
			</div>
		)
	}

	export default LoginScreen;