import { useState } from 'react';
import ShipsLegend from '../../ShipsLegend';
import SizesMenu from '../SizesMenu';
import styles from './styles.module.scss';

const VesselsMenu = () => {
	const [menu, setMenu] = useState();

	const MENUS = [
		<SizesMenu />,
		<ShipsLegend />
	]

	return (
		<div className={styles.VesselsMenu}>
			<ul>
				<li onClick={()=> setMenu(1)}>Leyenda y Filtros</li>
				{
					menu===1 && MENUS[menu]
				}
				<li onClick={()=> setMenu(0)}>Tamaño de íconos</li>
				{
					menu===0 && MENUS[menu]
				}
			</ul>
		</div>
	)
}

export default VesselsMenu;