import {toShortName} from '../data';
import styles from './styles.module.scss';
import AlarmCard from './AlarmCard';

const AlarmCards = ({data}) => {
	return (
		<div className={styles.cards}>
			{
				data?.map(issue => 
					<AlarmCard priority={issue.score} name={toShortName(issue.name)} data={issue} key={issue.issueId} />)
			}
		</div>
	)
}


export default AlarmCards;