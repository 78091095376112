import MapSection from './MapSection';
import AlarmsSection from './AlarmsSection';
import DetailsSection from './DetailsSection';
import styles from './styles.module.scss';

const MainScreen = () => {
	return (
		<div className={styles.mainScreen}>
			<MapSection  />
			<AlarmsSection />
			<DetailsSection />
		</div>
	)
}

export default MainScreen;