import {useEffect, useRef, forwardRef, useContext} from "react";
import {Marker} from "react-leaflet";
import "leaflet-rotatedmarker";
import {MapContext} from '@context/MapProvider';
import axios from 'axios';

const Ship = forwardRef(({ children, ...props }, forwardRef) => {
  const {contextData, setContextData} = useContext(MapContext);
	const {issues, routeTrajectories} = contextData;
	const markerRef = useRef();

  const { rotationAngle, rotationOrigin } = props;
  useEffect(() => {
    const marker = markerRef.current;
    if (marker) {
      marker.setRotationAngle(rotationAngle);
      marker.setRotationOrigin(rotationOrigin);
    }
  }, [rotationAngle, rotationOrigin]);

	function onMarkerClick(){
		const selectedVesselInfo = props.data;
		setContextData(prev=>({...prev, selectedVesselInfo: props.data}));
		const filteredIssues = issues.filter(x => x.vesselId === selectedVesselInfo?.vesselId);
		setContextData(prev=>({...prev, rightPanelContent: filteredIssues}));
	}

	useEffect(() => {
		// markerRef?.bindTooltip("Test Label", {	permanent: true, direction: 'right'	});
		props.setPopupRefs(prev=>({...prev, [props.data.vesselId]: markerRef}))
	}, [markerRef]);

	function onRightClick() {
		const selectedVesselInfo = props.data;

		const isVesselInRoutesList = routeTrajectories.find(x=> x.vesselId === selectedVesselInfo.vesselId);
		if (isVesselInRoutesList) {
			let newRoutesList = routeTrajectories.filter(x=> x.vesselId !== selectedVesselInfo.vesselId);
			setContextData(prev=> ({...prev, routeTrajectories: newRoutesList}));
		} else if (!isVesselInRoutesList && selectedVesselInfo?.vesselId) {

			let vesselId = selectedVesselInfo.vesselId;
			axios.get(`/data/visual/${vesselId}`)
			.then(res=> {
				const {time, coordinates} = res.data.positions;
				let timestamps = time;
				let positions = coordinates;
				const newRouteData = {	vesselId, positions, timestamps	};
				setContextData(prev=> ({...prev, routeTrajectories: [...prev.routeTrajectories, newRouteData]}));
			})
			.catch(err=> console.error(err.message));
		}
	}

  return (
    <Marker
      ref={(ref) => {
        markerRef.current = ref;
        if (forwardRef) {
          forwardRef.current = ref;
        }
      }}
      {...props}
			eventHandlers={{click: onMarkerClick, contextmenu: onRightClick}}
    >
      {children}
    </Marker>
  );
});

export default Ship;