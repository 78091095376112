import styles from './styles.module.scss';

const SpeedVariation = ({speedVariation, last, actual}) => {
	return (
		<div className={styles.speedVariation}>
			<p>Anterior: {last.speed.toPrecision(4)} nudos</p>
			<p>Actual: {actual.speed.toPrecision(4)} nudos</p>
			<p>Variación: {speedVariation.toPrecision(4)} nudos</p>
		</div>
	)
}

export default SpeedVariation;