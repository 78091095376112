import {ReactComponent as General} from '@images/vessels/general.svg';
import {ReactComponent as Fishing} from '@images/vessels/fishing.svg';
import {ReactComponent as Tugs} from '@images/vessels/tugs.svg';
import {ReactComponent as Pleasure} from '@images/vessels/pleasure.svg';
import {ReactComponent as HighSpeed} from '@images/vessels/highSpeed.svg';
import {ReactComponent as Passenger} from '@images/vessels/passenger.svg';
import {ReactComponent as Cargo} from '@images/vessels/cargo.svg';
import {ReactComponent as Tanker} from '@images/vessels/tanker.svg';

export const VESSEL_SCHEMA = [
	{typeId: 0, icon: <General />, color: 'white'},
	{typeId: 1, icon: <Fishing />, color: 'white'},
	{typeId: 2, icon: <Tugs />, color: 'white'},
	{typeId: 3, icon: <Pleasure />, color: 'white'},
	{typeId: 4, icon: <HighSpeed />, color: 'white'},
	{typeId: 5, icon: <Passenger />, color: 'white'},
	{typeId: 6, icon: <Cargo />, color: 'white'},
	{typeId: 7, icon: <Tanker />, color: 'white'},
];

export function getVesselSchema(typeId) {
	if (!typeId || typeId > 7 || typeId < 0) {
		typeId = 0;
	}
	return VESSEL_SCHEMA.find(x => Number(x.typeId) === Number(typeId))?.icon;
}