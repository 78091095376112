import { MapContext } from '@context/MapProvider';
import { useContext, useRef, useEffect } from 'react';
import styles from './styles.module.scss';

const MarkersPanel = ({markers, hoveredMarker, selectedMarker}) => {
	const {contextData, setContextData} = useContext(MapContext);
	const {zoneMarkers} = contextData;
	const markerPanel = useRef();

	useEffect(()=> {
		markerPanel.current?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
	}, [zoneMarkers])

	function onInputChange(e) {
		const {name, id, value} = e.target;
		const markerRef = zoneMarkers.find(x => x._leaflet_id == id);
		const {lat, lng} = markerRef.getLatLng();
		
		if (name === 'lat') markerRef.setLatLng([value, lng]);
		if (name === 'lng') markerRef.setLatLng([lat, value]);

		setContextData(prev=>({...prev, lines: getLatLngs()}))
	}
	function getLatLngs() {
		return zoneMarkers.map(m => { return [m.getLatLng().lat, m.getLatLng().lng] }); 
	}

	return ( 
		<div className={styles.markersPanel} >
			{
				markers?.map((m, i) => (
					<fieldset id={m._leaflet_id} 
						ref={markerPanel}
						style={{
							border: `${hoveredMarker===m._leaflet_id?'1px solid red':''}`,
							backgroundColor: `${selectedMarker===m._leaflet_id?'var(--blue-4)':''}`,}}
							key={m._leaflet_id}
							>
						<legend>Marcador #{i+1}</legend>
						<label>
							<span>Latitud:</span>
							<input 
								onChange={onInputChange}
								type="number" step={0.01} value={m.getLatLng().lat} name={'lat'} id={m._leaflet_id}/>
						</label>
						<label>
							<span>Longitud:</span>
							<input 
								onChange={onInputChange}
								type="number" step={0.01} value={m.getLatLng().lng} name={'lng'} id={m._leaflet_id}/>
						</label>
						{/* <LocationInput title='Longitud' value={m.getLatLng().lng} name={'lng'} id={m._leaflet_id} onChange={onInputChange}/> */}
					</fieldset>
				))
			}
		</div>
	)
}

export default MarkersPanel;